import { Form } from "react-bootstrap";
import React from "react";
import { apiCall } from "../../helpers/ApiHelper";

function Nickname(props) {
    const nicknameRef = React.useRef("");
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const [ loading, setLoading ] = React.useState(false);
    const [ alertMessage, setAlertMessage ] = React.useState(null);
    const [ success, setSuccess ] = React.useState(false);
    const addNickname = () => {
        setLoading(true)
        if(nicknameRef.current.value != "" && props?.printed_serial) {
            // check value less than 50 characters
            if(nicknameRef.current.value.length > 50) {
                setAlertMessage({
                    type: "danger",
                    message: "Nickname should be less than 50 characters."
                })
                setLoading(false)
                return false;
            }

            const requestOptions = {
                body: JSON.stringify({
                    NickName: nicknameRef.current.value,
                    PrintedSerial: props.printed_serial
                })
            };
            apiCall("POST", SERVER_URL, `Manjalink/update/nickname`, requestOptions)
            .then((response) => {
                console.log(response)
                if(response.status == 200) {
                    setAlertMessage({
                        type: "success",
                        message: "Nickname update successfully."
                    })
                    setSuccess(true)
                    setLoading(false)
                }
                else {
                    setAlertMessage({
                        type: "danger",
                        message: "Nickname update failed."
                    })
                    setLoading(false)
                }
            })
        }
        else {
            setAlertMessage({
                type: "danger",
                message: "Please enter nickname."
            })
            setLoading(false)
        }
    }
    return (
        <Form>
            <p>It'll help you identify the card in future.</p>
            <Form.Label>Enter nickname:</Form.Label>
            <Form.Control type="text" 
            maxLength={50}
            placeholder="Nickname" ref={nicknameRef} />
            {alertMessage ? <div className={`text-${alertMessage.type}`} >{alertMessage.message}</div> : ""}
            <div className="text-center mt-3">
                {
                    !success ?
                    <>
                    <button className="btn btn-primary button-cancel" type="button" onClick={props.onHide}>Cancel</button>
                    <button className="btn btn-primary button-submit" type="button" onClick={addNickname} disabled={loading}>Save</button>
                    </>
                    :
                    <button className="btn btn-primary button-submit" type="button" onClick={()=>window.location.reload()} >Done</button>
                }

            </div>
        </Form>
    )
}
export default Nickname;