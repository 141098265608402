import React, { useState, useRef, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { apiCall } from "../../helpers/ApiHelper";
import { setLoginStatus, setPrimaryEmail, setUserName, setDiversifyKey, setRegisterStatus } from "../../helpers/StorageHelper";
import { checkIsFieldBlank, emailFormat } from "../../helpers/ValidationHelper";
import HeaderBackgroundImage from "../HeaderBackgroundImage";
import LoadingScreen from "../LoadingScreen";
import './form.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

function Login() {
  const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
  const [post, setPost] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(()=>{
    sessionStorage.clear();
  },[])

  //   const { data: posts, error, loading } = API({
  //   method: 'GET',
  //   endpoint: 'https://jsonplaceholder.typicode.com/posts',
  // });
  const userLogin = (e) => {
    setError(null);
    // console.log(email.current.value);
    // console.log(password.current.value);
    const isFieldBlank = checkIsFieldBlank([email.current.value, password.current.value]);
    const isCorrectEmailFormat = emailFormat(email.current.value)
    
    // console.log(isFieldBlank)
    // console.log(isCorrectEmailFormat)

    if(isFieldBlank == false) {
      if(isCorrectEmailFormat == true) {
        setLoading(true);
        const requestOptions = {
          body: JSON.stringify({ 
            Email: email.current.value, 
            Password: password.current.value 
          })
        }
        apiCall('POST', SERVER_URL, `ManjaProfile/WebMemberLogin`, requestOptions)
        .then(
          result => {
            // console.log(result)
            if(result.api_result == null) {
              e.preventDefault();
              setLoading(false)
              setError("Invalid Email or Password!");
            }
            else if(result.status == '201') {
              setPrimaryEmail(result.api_result);
              window.location.href = '/pendingVerification';
            }
            else {
              setData(result.api_result)
              setLoginStatus(true);
              setUserName(result.api_result[0].full_name);
              setDiversifyKey(result.api_result[0].diversify_key);
              setPrimaryEmail(result.api_result[0].email);
              if(result.api_result[0].register_status == '3') {
                setRegisterStatus(3)
                window.location.href = '/editProfile';
              }
              else {
                window.location.href = '/myAccount';
              }
            }
          }
        )
      // props.setLogin(true);
      }
      else {
        setError("Please Enter Email in Correct Format!");
      }
  }
  else {
    setError("Please Fill In Email and Password!");
  }
}
  // console.log(data)
  // console.log(error)
  const email = useRef(null);
  const password = useRef(null);

  if (loading) return <LoadingScreen />;
  // if (error) return <p>Error: {error.message}</p>;
  return (
    <div className="form">
        <HeaderBackgroundImage />
        <Form>
        <div className="loginRegister">
          <div className="text-center bg-white">
            <h4>Login</h4>            
          </div>
          <div className="loginRegister-form-container">
            <div>
                <Row>
                    <Col>
                        <Form.Label>Email</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type='email' ref={email} required></Form.Control>
                    </Col>
                </Row>
            </div>
            <div>
                <Row>
                    <Col>
                        <Form.Label>Password</Form.Label>
                    </Col>
                </Row>
                <Row>
                  <Col className="position-relative">
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        ref={password}
                        required
                        className="password-input"
                      />
                      <div
                        className="password-toggle-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                      </div>
                    </Col>
                </Row>
            </div>
            <div className="text-end">
              <Link to="/forgotPassword" className="link-dark">
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
        {
          error ? 
          <div className="mw-600 m-auto mt-2">
              <label className="form-label label-danger ps-4 pe-3">{error}</label>
          </div>
          : ""
        }
        <div className="text-center mb-2">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={userLogin}
          >
            LOGIN
          </button>
        </div>
        <div className="text-center small-text">
          <p>Need an account?&nbsp;</p>
          <Link to="/register" className="link-success">
            Register
          </Link>
        </div>
        </Form>
    </div>
  );
}
export default Login;
