import React, { useEffect, useState } from "react";

function ReloadCard(props) {
    const [reload, setReload] = useState({});
    let fullReload = '';

    const setField = (field, value) => {
        setReload({
            ...reload, 
            [field]: value,
        })
    }
    // console.log(reload);
    useEffect(()=> {
        const field1 = document.getElementById("field-1");
        const field2 = document.getElementById("field-2");
        const field3 = document.getElementById("field-3");
        const field4 = document.getElementById("field-4");

        if(field3.value.length == 4 && field2.value.length == 4 && field1.value.length == 4) {
            field4.focus();
        }
        else if(field2.value.length == 4 && field1.value.length == 4) {
            field3.focus();
        }
        else if(field1.value.length == 4) {
            field2.focus();
        }

        if(reload != {} && reload.length != 0) {
            for (const key in reload) {
                fullReload += reload[key]
            }
            // console.log(fullReload);
            props?.reloadCard(fullReload)
        }
    },[reload])


    return (
        <div className="text-center">
            <label className="form-label fw-bold" >Key in 16-Digit Activation Code</label>
            <div className="table-responsive">
                <table className="table">
                    <tbody className="bs-none">
                        <tr className="d-flex d-xxl-flex align-items-center align-items-xxl-center bs-none">
                            <td className="bs-none">
                                <input type="text" id="field-1" className="reload-field text-center" onChange={e=>setField("field1", e.target.value)} autofocus="" maxlength="4" />
                            </td>
                            <td className="align-items-xxl-center bs-none" >-</td>
                            <td className="bs-none">
                                <input type="text" id="field-2" className="reload-field text-center" onChange={e=>setField("field2", e.target.value)} autofocus="" maxlength="4" />
                            </td>
                            <td className="bs-none">-</td>
                            <td className="bs-none">
                                <input type="text" id="field-3" className="reload-field text-center" onChange={e=>setField("field3", e.target.value)} autofocus="" maxlength="4" />
                            </td>
                            <td className="bs-none">-</td>
                            <td className="bs-none">
                                <input type="text" id="field-4" className="reload-field text-center" onChange={e=>setField("field4", e.target.value)} autofocus="" maxlength="4" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default ReloadCard;