import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { changeDateFormatToDDMMMYYYY, changeDateTimeFormatToDDMMMYYYY, convertTimeStampDateToYYYYMMDD } from "../helpers/DateHelper";
import { ArrayIntoChunk } from "../helpers/PaginationHelper";
import ModalVertical from "./ModalVertical";
import SingleCardInfo from "./SingleCardInfo";
import TitleWithBackButton from "./TitleWithBackButton";

function TransactionHistory(props) {
    const cu_id = props?.cu_id;
    const printed_serial = props?.printed_serial;
    const transHistory = props?.transHistory;
    const [transHistoryPagination, setTransHistoryPagination] = useState(null);
    const design_images = props?.design_images;
    const current_balance = props?.current_balance;
    const card_exp_date = props?.card_exp_date;
    const rebate_amt = props?.rebate_amt;
    const loyaltyPoints = props?.loyaltyPoints;
    const [selectedReportModal, setSelectedReportModal] = useState(null);
    const [showReportModal, setShowReportModal] = useState(false);

    const rows_per_page = 20;
    const [page, setPage] = useState(0);
    useEffect(()=>{
        if(transHistory && transHistory.length > rows_per_page) {
            setTransHistoryPagination(ArrayIntoChunk(transHistory, rows_per_page))
        }
    },[transHistory])
    
    // console.log(props)
    // console.log(transHistory)
    // console.log(transHistoryPagination)
    // console.log(page)

    function prevPage() {
        if(page > 0) {
            setPage(page-1);
        }
    }

    function nextPage() {
        if(page < transHistoryPagination.length-1) {
            setPage(page+1);
        }
    }

    // console.log(props?.header)
    if(props.title == 'loyalty') {
        // loyalty
        if(transHistory != null && transHistory.length > rows_per_page) {
            // loyalty with pagination
            return (
                <>
                <div className="container">
                    <TitleWithBackButton 
                    backto = '/myAccount'
                    title = 'Transaction History'
                    />
                    <div>
                        {/* <h6 className="text-center mb-2 fw-bold">Your Transaction will be available in your online transaction history report only after 7 working days.</h6> */}
                    </div>
                    <SingleCardInfo
                    title = {props?.title}
                    cu_id = {cu_id}
                    printed_serial = {printed_serial}
                    design_images = {design_images}
                    current_balance = {current_balance}
                    rebate_amt = {rebate_amt}
                    card_exp_date = {card_exp_date}
                    currentDate = {props?.currentDate}
                    loyaltyPoints = {loyaltyPoints}
                    />
        
                    <div className="basic-container">
                        <div className="table-responsive basic-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {
                                           props?.header.map((header_item) =>
                                           <th>{header_item}</th>
                                           )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transHistoryPagination && transHistoryPagination[page].map(({date, expirydate, mode, point, reference}, index)=>(
                                            <tr>
                                                <td>{date}</td>
                                                <td>{reference}</td>
                                                <td>{mode}</td>
                                                <td>{point}</td>
                                                <td>{expirydate}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <nav className="d-xl-flex d-xxl-flex justify-content-xl-end justify-content-xxl-end mw-800 m-auto mt-2">
                        <ul className="pagination">
                            <li className="page-item">
                                <a className="page-link pointer" aria-label="Previous" onClick={()=>prevPage()}>
                                    <span aria-hidden="true">«</span>
                                </a>
                            </li>
                                {
                                    transHistoryPagination && transHistoryPagination.length > 0 ?
                                    Array.from({ length: transHistoryPagination.length }, (_, i) =>
                                        <li className="page-item"><a className="page-link pointer" onClick={()=>setPage(i)}>{i+1}</a></li>
                                    )
                                    : ""
                                }
                            <li className="page-item pointer">
                                <a className="page-link" aria-label="Next" onClick={()=>nextPage()}>
                                    <span aria-hidden="true">»</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                </>
            )
        }
        else {
            // console.log(props.header)
            // loyalty without pagination
            return (
                <>
                <div className="container">
                    <TitleWithBackButton 
                    backto = '/myAccount'
                    title = 'Transaction History'
                    />
                    <div>
                        {/* <h6 className="text-center mb-2 fw-bold">Your Transaction will be available in your online transaction history report only after 7 working days.</h6> */}
                    </div>
                    <SingleCardInfo
                    title = {props?.title}
                    cu_id = {cu_id}
                    printed_serial = {printed_serial}
                    design_images = {design_images}
                    current_balance = {current_balance}
                    card_exp_date = {card_exp_date}
                    loyaltyPoints = {loyaltyPoints}
                    />
        
                    <div className="basic-container">
                        <div className="table-responsive basic-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {
                                            props?.header.map((header_item)=>
                                                <th>{header_item}</th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transHistory != null ?
                                        transHistory && transHistory.map(({date, expirydate, mode, point, reference}, index)=>(
                                            <tr>
                                                <td>{date}</td>
                                                <td>{reference}</td>
                                                <td>{mode}</td>
                                                <td>{point}</td>
                                                <td>{expirydate}</td>
                                            </tr>
                                        ))
                                        : 
                                        <tr>
                                            <td colSpan={4} className="text-center">No result found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </>
            )
        }
    }
    else {
        const makeDispute = (trans_data, userData, printed_serial, title, card_is_cancelled) => {
            setSelectedReportModal({
                trans_data: trans_data,
                userData: userData,
                printed_serial: printed_serial,
                title: title ?? "ManjaLink",
                card_is_cancelled: card_is_cancelled ?? false
            });
            setShowReportModal(true);
        }
        // manjalink, manjapay

        if(showReportModal && selectedReportModal != null) {
            return (
                <>
                <ModalVertical 
                title="Transaction Details"
                class="primary-heading-color"
                onHide={()=>setShowReportModal(false)}
                selectedReportModal={selectedReportModal}
                /> 
                </>
            )
        }
        if(transHistory != null && transHistory.length > rows_per_page) {
            // manjalink, manjapay with pagination
            return (
                <>
                <div className="container">
                    <TitleWithBackButton 
                    backto = '/myAccount'
                    title = 'Transaction History'
                    />
                    <div>
                        <h6 className="text-center mb-2 fw-bold">Your Transaction will be available in your online transaction history report only after 1 working day.</h6>
                    </div>
                    <SingleCardInfo
                    title = {props?.title}
                    cu_id = {cu_id}
                    rebate_amt = {rebate_amt}
                    design_images = {design_images}
                    current_balance = {current_balance}
                    card_exp_date = {card_exp_date}
                    />
        
                    <div className="basic-container">
                        <div className="table-responsive basic-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {
                                           props?.header.map((header_item) =>
                                           <th>{header_item}</th>
                                           )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transHistoryPagination && transHistoryPagination[page].map(({ID, created_timestamp, type, amount}, index)=>(
                                            <tr>
                                                <td>{ID}</td>
                                                <td>{changeDateTimeFormatToDDMMMYYYY(created_timestamp)}</td>
                                                <td>{type}</td>
                                                <td>{amount}</td>
                                                <td><button className="primary-button" type="button" onClick={()=>makeDispute(transHistoryPagination[page][index], props.userData, props.printed_serial, props?.title, props?.card_is_cancelled)}>Details</button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <nav className="d-xl-flex d-xxl-flex justify-content-xl-end justify-content-xxl-end mw-800 m-auto mt-2">
                        <ul className="pagination">
                            <li className="page-item">
                                <a className="page-link pointer" aria-label="Previous" onClick={()=>prevPage()}>
                                    <span aria-hidden="true">«</span>
                                </a>
                            </li>
                                {
                                    transHistoryPagination && transHistoryPagination.length > 0 ?
                                    Array.from({ length: transHistoryPagination.length }, (_, i) =>
                                        <li className="page-item"><a className="page-link pointer" onClick={()=>setPage(i)}>{i+1}</a></li>
                                    )
                                    : ""
                                }
                            <li className="page-item pointer">
                                <a className="page-link" aria-label="Next" onClick={()=>nextPage()}>
                                    <span aria-hidden="true">»</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                </>
            )
        }
        else {
            // console.log(props.header)
            // manjalink, manjapay without pagination
            return (
                <>
                <div className="container">
                    <TitleWithBackButton 
                    backto = '/myAccount'
                    title = 'Transaction History'
                    />
                    <div>
                        <h6 className="text-center mb-2 fw-bold">Your Transaction will be available in your online transaction history report only after 1 working day.</h6>
                    </div>
                    <SingleCardInfo
                    title = {props?.title}
                    cu_id = {cu_id}
                    rebate_amt = {rebate_amt}
                    printed_serial = {printed_serial}
                    design_images = {design_images}
                    current_balance = {current_balance}
                    card_exp_date = {card_exp_date}
                    />
        
                    <div className="basic-container">
                        <div className="table-responsive basic-table font-sub-title-large">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {
                                            props?.header.map((header_item)=>
                                                <th>{header_item}</th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transHistory != null ?
                                        transHistory && transHistory.map(({ID, created_timestamp, type, amount}, index)=>(
                                            <tr className="vertical-middle">
                                                <td>{ID}</td>
                                                <td>{changeDateTimeFormatToDDMMMYYYY(created_timestamp)}</td>
                                                <td>{type}</td>
                                                <td>{amount}</td>
                                                <td><button className="primary-button" type="button" onClick={()=>makeDispute(transHistory[index], props.userData, props.printed_serial, props?.title, props?.card_is_cancelled)}>Details</button></td>
                                            </tr>
                                        ))
                                        : 
                                        <tr>
                                            <td colSpan={5} className="text-center">No result found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </>
            )
        }
    }
}
export default TransactionHistory;