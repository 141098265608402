import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { apiCall } from "../../helpers/ApiHelper";
import { checkIsFieldBlank } from "../../helpers/ValidationHelper";
import parse from 'html-react-parser';
import './form.css';

function AddCard(props) {
    const manjalink_id = props?.manjalink_id;
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;

    const [cardId, setCardId] = useState('');
    const [scratchCode, setScratchCode] = useState('');
    const [scratchCodeField, setScratchCodeField] = useState(false);
    const [ic, setIC] = useState('');
    const [icField, setICField] = useState(false);

    const [cardIdError, setCardIdError] = useState(false);
    const [fieldError, setFieldError] = useState(false);

    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [failedWithErrMsg, setFailedWithErrMsg] = useState(null);
    // console.log(failedWithErrMsg)
    const [loading, setLoading] = useState(false);

    // console.log(manjalink_id)
    useEffect(()=>{
        if(cardId.length == 8) {
            if(parseInt(cardId)>=19001){
                //show scratch code field
                // console.log("scratch code")
                setIC('')
                setICField(false)
                setScratchCodeField(true)
            }
            else {
                //show ic field
                // console.log("IC")
                setScratchCode('')
                setICField(true)
                setScratchCodeField(false)
            }
        }
        else {
            setScratchCodeField(false)
            setICField(false)
        }
    },[cardId])

    const addNewCard = () => {
        setCardIdError(false)
        setFieldError(false)
        setSuccess(false)
        setFailed(false)
        setFailedWithErrMsg(null)
        if(cardId && cardId.length != 8) {
            setCardIdError(true)
        }
        else {
            setLoading(true)
            if(checkIsFieldBlank([cardId, scratchCode]) == false) {
                // scratch code
                // if scratch code character length is not 7, add 0 in front until 7 characters
                let fullScratchCode = scratchCode;
                while(fullScratchCode.length != 7) {
                    fullScratchCode = '0'+fullScratchCode;
                }

                const requestOptions = {
                    body: JSON.stringify({
                        "ScratchCode": fullScratchCode,
                        "PrintedSerial": cardId,
                        "ManjaLinkID": manjalink_id
                    })
                }
                apiCall('POST', SERVER_URL, 'Manjalink/AddCard', requestOptions)
                .then(response=>{
                    if(response) {
                        // console.log(response)
                        setLoading(false)
                        if(response.status == '204' || response.status == '200') {
                            setSuccess(true)
                        }
                        else {
                            if(response?.error?.message) {
                                // console.log(response?.error?.message)
                                setFailedWithErrMsg(response?.error?.message)
                            }
                            else {
                                setFailed(true)
                            }
                        }
                    }
                })
            }
            else if(checkIsFieldBlank([cardId, ic]) == false) {
                // ic
                const requestOptions = {
                    body: JSON.stringify({
                        "DocumentID": ic,
                        "PrintedSerial": cardId,
                        "ManjaLinkID": manjalink_id
                    })
                }
                apiCall('POST', SERVER_URL, 'Manjalink/AddCard', requestOptions)
                .then(response=>{
                    if(response) {
                        // console.log(response)
                        setLoading(false)
                        if(response.status == '204' || response.status == '200') {
                            setSuccess(true)
                        }
                        else {
                            if(response?.error?.message) {
                                // console.log(response?.error?.message)
                                setFailedWithErrMsg(response?.error?.message)
                            }
                            else {
                                setFailed(true)
                            }
                        }
                    }
                })
            }
            else {
                setLoading(false)
                setFieldError(true)
            }
        }
    }

    const redirectToMyAccount = () => {
        window.location.href = "/myAccount";
    }

    return (
        <div className="pop-out-modal">
            <div>
                <h5>Card ID</h5>
                <Form>
                    <Form.Control type="text" maxLength={8} onChange={e=>setCardId(e.target.value)} placeHolder='Card ID'></Form.Control>
                    {
                        scratchCodeField ? 
                        <>
                        <Form.Control type="text" maxLength={7} onChange={e=>setScratchCode(e.target.value)} placeHolder='Scratch Code'></Form.Control> 
                        <img src="assets/img/manjalink_card_scratch_code_sample_2.svg" alt="scratch_code_example" className="scratch_code_svg" />
                        </>:
                        icField ? <Form.Control type="text" maxLength={20} onChange={e=>setIC(e.target.value)} placeHolder='IC No./Passport No.'></Form.Control> :
                        ""
                    }
                    {
                        cardIdError ? <label className="form-label label-danger">Please key in a valid card ID.</label> :
                        fieldError ? <label className="form-label label-danger">Please fill in all required field.</label> :
                        failed ? <label className="form-label label-danger">Oops! It looks like something went wrong. We're sorry for any inconvenience caused. If you have any questions or concerns, please email us at <a href='mailto:feedback@manjalink.com.my'>feedback@manjalink.com.my</a> and we'll do our best to assist you.</label> :
                        failedWithErrMsg ? <label className="form-label label-danger">{parse(failedWithErrMsg.replace(/"/g,""))}</label> :
                        success ? <label className="form-label label-success">Card Added Successfully</label> :
                        ""
                    }
                </Form>
            </div>
            <div className="text-center">
                {
                    !success ?
                    <>
                    <button className="btn btn-primary button-cancel" type="button" onClick={props.onHide}>Cancel</button>
                    <button className="btn btn-primary button-submit" type="button" onClick={addNewCard} disabled={loading}>Submit</button>
                    </>
                    :
                    <button className="btn btn-primary button-submit" type="button" onClick={redirectToMyAccount} >Done</button>
                }
            </div>
        </div>
    )
}
export default AddCard;