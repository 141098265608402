import React, { useState, useEffect } from "react";
import LostOrCancelCard from "../components/forms/LostOrCancelCard";
import LoadingScreen from "../components/LoadingScreen";
import { apiCall } from "../helpers/ApiHelper";

function LostOrCancelCardPage() {
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const [ bankList, setBankList ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    useEffect(()=>{
        apiCall('GET', SERVER_URL, 'Manjalink/SelectBankList')
        .then(result=>{
            setBankList(result);
            // console.log(result)
            setLoading(false)
        })
    },[])
    if(loading) return (<LoadingScreen />)
    return (
        <LostOrCancelCard bankList={bankList} />
    )
}
export default LostOrCancelCardPage;