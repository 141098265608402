import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { apiCall } from "../helpers/ApiHelper";
import LoadingScreen from "./LoadingScreen";
import OnlineReloadStep from "./OnlineReloadStep";
import SingleCardInfo from "./SingleCardInfo";
import TitleWithBackButton from "./TitleWithBackButton";
import "./TopUpPaymentAck.css";

function TopUpPaymentAck() {
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const OLD_MANJA_API = process.env.REACT_APP_OLD_MANJAAPI_URL;
    const redirectToMyAccount = () => {
        window.location.href = '/myAccount';
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const status_id = searchParams.get("status_id");
    const order_id = searchParams.get("order_id");
    const transaction_id = searchParams.get("transaction_id");
    const msg = searchParams.get("msg");
    const hash = searchParams.get("hash");
    const payment_type = searchParams.get("payment_type");
    const [success, setSuccess] = useState(false);
    const [isManjaPay, setIsManjaPay] = useState(false);
    const [cardData, setCardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    useEffect(()=>{
        if(cardData == null && order_id != null) {
            const requestOptions = {
                body: JSON.stringify({
                    StatusID: status_id,
                    OrderID: order_id,
                    TransactionID: transaction_id,
                    Msg: msg,
                    Hash: hash,
                    PaymentType: payment_type
                })
            }
            apiCall('POST', SERVER_URL, `Manjalink/update/${order_id}`, requestOptions)
            .then(response => {
                // console.log(response)
                if(response) {
                    if(response.api_result) {
                        // setCardData(response.api_result[0])
                        if(response.api_result[0].printed_serial.substring(0,2) == "EW") {
                            setIsManjaPay(true);
                        }
                        const requestOptions2 = {
                            body: JSON.stringify({
                                status_id: status_id,
                                order_id: order_id,
                                msg: msg,
                                transaction_id: transaction_id,
                                hash: hash,
                                paykey: order_id,
                                currency: response.api_result[0].currency_id,
                                amt: response.api_result[0].top_up_amount,
                                manjalink_id: response.api_result[0].manjalink_id,
                                printed_serial: response.api_result[0].printed_serial
                            })
                        }
                        apiCall('POST', OLD_MANJA_API, 'Manjalink/OnlinePaymentSenangpayCallback2', requestOptions2)
                        .then(response => {
                            if(response) {
                                // console.log(response);
                                if(response.api_result) {
                                    setCardData(response.api_result?.CardStatus)
                                    setSuccess(true)
                                }
                                else {
                                    setSuccess(false)
                                }
                                setLoading2(false)
                            }
                        })
                    }
                    else {
                        setSuccess(false)
                        setLoading2(false)
                    }
                }
                else {
                    setSuccess(false)
                    setLoading2(false)
                }
                setLoading(false)
            })
        }
        else {
            setSuccess(false)
            setLoading(false)
            setLoading2(false)
        }
    },[order_id])
    // console.log(cardData)
    // const urlParts = urlString.split('/');
    // const fileName = cardData?.CardDesignURL.split('/')[urlString.split('/').length - 1];
    if(loading || loading2) return (<LoadingScreen />)
    return(
        <>
        <div className="container">
            <TitleWithBackButton 
            backto = '/myAccount'
            title = 'Top Up ManjaLink'
            />
            <OnlineReloadStep />
            {
                cardData ?
                <SingleCardInfo 
                cu_id = {cardData?.CuID}
                printed_serial = {cardData?.PrintedSerial}
                design_images = {cardData?.CardDesignURL && cardData?.CardDesignURL.includes("/") ? cardData?.CardDesignURL.split('/')[cardData?.CardDesignURL.split('/').length - 1] : cardData?.CardDesignURL}
                current_balance = {cardData?.CurrentBalance}
                top_up_amt = {cardData?.TopUpAmount}
                title = {isManjaPay ? "ManjaPay" : null}
                card_exp_date = {!isManjaPay ? cardData?.CardExpiry : null}
                />
                : ""
            }
            <div className="basic-container">
                {
                    status_id == '1' && order_id != null && transaction_id != null && msg != null && hash != null && success == true?
                    <div className="row">
                        {
                            !isManjaPay ?
                            <div className="col-sm-6">
                            <img src="assets/img/topup_successful.png" className="mw-100" />
                            </div>
                            : ""
                        }
                        <div className="col-sm-6">
                            <label className="form-label font-primary-success font-larger">Your top up is successful!</label>
                            {
                                !isManjaPay ?
                                <p>Please tap your card on the bus tapping machine at least 3 hours after the top-up, or alternatively, you may tap your card at any of our self-service machines for an immediate transfer of the travel credits.</p>
                                : ""
                            }
                            <p>Thank you.</p>
                        </div>
                    </div>
                    : 
                    <div className="row">
                        <div className="col-sm-6">
                            <img src="assets/img/topup_successful.png" className="mw-100" />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label label-danger font-larger">Your top up is Failed!</label>
                            <p>Please try again later.</p>
                            <p>Thank you.</p>
                        </div>
                    </div>
                }
            </div>
            <div className="mw-800 m-auto text-center mt-1">
                <button className="btn btn-primary primary-button" type="button" onClick={redirectToMyAccount}>Done&nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="bi bi-chevron-right">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                    </svg>
                </button>
            </div>
        </div>
        </>
    )
}
export default TopUpPaymentAck;