import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './Navbar.css';

function Footer() {
    const MANJALINK_DOMAIN = process.env.REACT_APP_MANJALINK_DOMAIN
    const date = new Date();
    const year = date.getFullYear();
    return (
        <footer className="footer pt-5">
            <Container className="py-4 py-lg-5">
                <Row className="justify-content-center">
                    <Col sm={12} md={4} lg={3} className="text-center text-lg-start d-flex flex-column item mobile-hidden mb-3">
                        <h3 className="fs-6"><strong>Our Company</strong></h3>
                        <ul className="list-unstyled">
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}`}>Home</a></li>
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}about-us`}>About Us</a></li>
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}get-manjalink`}>Get ManjaLink</a></li>
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}news-promotions`}>News & Promotions</a></li>
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}travel-information`}>Travel Information</a></li>
                            <li><a className="link-secondary" href={`/login`}>Member Login/Join</a></li>
                        </ul>
                    </Col>
                    <Col sm={12} md={4} lg={3} className="text-center text-lg-start d-flex flex-column item mobile-hidden mb-3">
                        <h3 className="fs-6">
                            <strong>Product</strong>
                            <br />
                        </h3>
                        <ul className="list-unstyled">
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}manja-link`}>ManjaLink</a></li>
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}manja-pay`}>ManjaPay</a></li>
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}manja-rewards`}>ManjaRewards</a></li>
                        </ul>
                        <h3 className="fs-6">
                            <strong>Customer Services</strong>
                            <br />
                        </h3>
                        <ul className="list-unstyled">
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}customer-service`}>FAQs</a></li>
                            <li><a className="link-secondary" href={`${MANJALINK_DOMAIN}customer-service/#contact-us`}>Contact Us</a></li>
                        </ul>
                    </Col>
                    <Col sm={12} md={12} lg={3} className="text-center text-lg-start d-flex flex-column order-md-first item mb-3">
                        <a href={MANJALINK_DOMAIN}>
                            <img src="assets/img/manja_logo_coloured1-300x101.png" className="footer-logo"/>
                        </a>
                        <a className="fs-12" href="https://goo.gl/maps/afDezr8qP9hhyL2YA" target="_blank">
                            <span>No. 23, Jalan Firma 2,</span><br />
                            <span>Kawasan Perindustrian Tebrau IV,</span><br />
                            <span>81100 Johor Bahru, Johor,</span><br />
                            <span>Malaysia</span><br />
                        </a>
                        <h3 className="fs-6 mt-4"><strong>Connect with us</strong></h3>
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item circle">
                                <a href="https://www.facebook.com/Manja.CW/" target="_blank">
                                    <img src = "assets/img/icon_fb.png" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.instagram.com/manja.cw/" target="_blank">
                                    <img src = "assets/img/icon_ig.png" />
                                </a>
                            </li>
                            {/* <li className="list-inline-item">
                                <a href="https://twitter.com/lugoapp" target="_blank">
                                    <img src = "assets/img/icon_twitter.png" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://twitter.com/lugoapp" target="_blank">
                                    <img src = "assets/img/icon_tiktok.png" />
                                </a>
                            </li> */}
                        </ul>
                    </Col>
                    <Col sm={12} md={4} lg={3} className="text-center text-lg-start d-flex flex-column align-items-center align-items-lg-start item social mb-3 mt-2">
                        <div className="fw-bold d-flex align-items-center mb-2">
                            <span>Download 
                                <div className="icon-container">
                                    <img src="assets/img/lugo_logo_coloured.png" className="lugo-icon" />
                                </div>
                                App
                            </span>
                        </div>
                        <p className="text-muted copyright">
                            <span className="fs-12">The easiest way to manage your Manja payments.</span>
                            <br />
                        </p>
                        <Row className="g-0">
                            <Col sm={12}>
                                <a href="https://play.google.com/store/apps/details?id=com.manja.hugoapp&amp;hl=en&amp;gl=US" target="_blank">
                                <img src="assets/img/hugo-google-play-badge.png" className="google-play-icon" />
                                </a>
                                <a href="https://apps.apple.com/my/app/lugo-food-news-transit/id1148798974" target="_blank" className="ps-1">
                                    <img src="assets/img/icon_appstore.png" className="app-store-icon" />
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col sm={12} md={6}>
                        <p className="text-center text-muted mb-0 fs-15">
                            <span>Copyright © Handal Indah {year}. All Rights Reserved.</span>
                            <br />
                        </p>
                    </Col>
                    <Col sm={12} md={6} className="text-center fs-15">
                        <a href={`${MANJALINK_DOMAIN}general-terms-of-use/`} target="_blank" className="inline-block">
                        <span>Terms &amp; Conditions</span>
                        <br />
                        </a>
                        <span>&nbsp; |&nbsp;&nbsp;</span>
                        <a href={`${MANJALINK_DOMAIN}privacy-policy/`} target="_blank" className="inline-block">Privacy Policy
                        <br /></a>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
export default Footer;