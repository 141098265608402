import React from "react";
import { styled } from '@mui/material/styles';
import { Tooltip, ClickAwayListener } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';

function MyToolTips(props) {

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }));
      
    const [open, setOpen] = React.useState(false);
    
    const handleTooltipClose = () => {
      setOpen(false);
    };
    
    const handleTooltipOpen = () => {
      setOpen(true);
    };
    
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <LightTooltip
                PopperProps={{
                disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                placement={props?.position ?? "bottom"}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={props.title}
            >
                <i className="fas fa-info-circle text-secondary" onMouseLeave={handleTooltipClose} onMouseOver={handleTooltipOpen} onClick={handleTooltipOpen}></i>
            </LightTooltip>
        </ClickAwayListener>
    )
}
export default MyToolTips;