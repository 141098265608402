import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { apiCall } from "../helpers/ApiHelper";
import { changeDateFormatToDDMMMYYYY } from "../helpers/DateHelper";
import './MyManjaLinkCard.css'

function MyCwlExpressLoyalty(props) {
    const [loyaltyPoints, setLoyaltyPoints] = useState(0);
    const email = props?.email;
    const MANJA_REWARDS_URL = process.env.REACT_APP_CWL_MANJA_REWARD_URL;
    const MANJA_REWARDS_API_KEY = process.env.REACT_APP_CWL_MANJA_REWARD_KEY;
    const loyaltyData = props?.loyaltyData;
    const today = new Date();
    // console.log(loyaltyPoints)
    useEffect(()=>{
        // {{manjareward_url}}/Transactions/summary/1/email/jxsia@handalindah.com.my
        const requestOptions = {
            headers: {
                apikey: MANJA_REWARDS_API_KEY
            }
        }
        apiCall('GET', MANJA_REWARDS_URL, `Transactions/summary/1/email/${email}`, requestOptions)
        .then(response => {
            // console.log(response)
            if(response) {
                setLoyaltyPoints(response?.results[0]?.availablepoint)
            }
        })
    },[])
    
    // console.log(loyaltyData)
    return (
        <Container>
            <Row>
                <Col sm={6}>
                    <div className="bw-0">
                        <label className="form-label fw-bold">Causeway Link Express Loyalty Point</label>
                        <div className="card br-20">
                            <div className="card-body"></div>
                            <div className="container">
                                <div className="row mb-3">
                                    <div className="col-sm-12 col-md-5 col-lg-4">
                                        <img src="assets/img/causewaylink_logo.png" />
                                    </div>
                                    <div className="col-sm-12 col-md-7 col-lg-8">
                                        <p className="mb-0">Balance</p>
                                        <h4 className="fw-bold">{ loyaltyPoints } PTs</h4>
                                        <div className="row">
                                            <div className="col p-1">
                                                <Link to="/transactionHistory" state={{title: 'loyalty', email: email, loyaltyPoints: loyaltyPoints, loyaltyData: loyaltyData, currentDate: changeDateFormatToDDMMMYYYY(today)}} className="font-primary-success ps-2">Transaction
                                                    <i className="fas fa-chevron-right ms-1"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-extra-info">
                                <p className="mb-0">As of {changeDateFormatToDDMMMYYYY(today)}</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default MyCwlExpressLoyalty;