import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/esm/Row';
import Modal from 'react-bootstrap/Modal';
import './LoadingScreen.css';

function LoadingScreen() {
    const [show, setShow] = useState(true);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });
        }
    }, [isLoading]);
    
    function simulateNetworkRequest() {
        return new Promise((resolve) => setTimeout(resolve, 50000));
    }

    if(isLoading) {
        return (
            <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w loadingContent"
            aria-labelledby="LoadingScreen"
            animation={false}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Body className="loadingContent">
                <Spinner animation="border" />
            </Modal.Body>
            </Modal>
        )
    }
}

export default LoadingScreen;