import React from "react";
import { apiCall } from "../../helpers/ApiHelper";
import './form.css';

function RemoveCard(props) {
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    // console.log(props)
    const manjalink_id = props?.manjalink_id;
    const printed_serial = props?.printed_serial;
    const removeCard = () => {
        // console.log(props)
        const requestOptions = {
            body: JSON.stringify({
                "ManjaLinkID": manjalink_id,
                "PrintedSerial": printed_serial
            })
        }
        if(printed_serial && manjalink_id) {
            apiCall('POST', SERVER_URL, 'Manjalink/RemoveCard', requestOptions)
            .then(response=>{
                // console.log(response)
                if (response.status == '204') {
                    alert("Card Removed Successfully");
                    window.location.reload();
                }
                else {
                    alert("Failed to remove card");
                }
            })
        }
        else {
            alert("Error while removing card, Please try again later");
            window.location.href = "/myAccount";
        }
    }
    return (
        <div className="pop-out-modal text-center">
            <h5 className="pb-2">Remove card will delete your transaction history permanently. <br/>Are you sure you want to remove card ID: {props.printed_serial}?</h5>
            <button className="btn btn-primary button-cancel" type="button" onClick={()=>props.onHide(false)}>Cancel</button>
            <button className="btn btn-primary button-remove" type="button" onClick={removeCard}>Confirm Remove</button>
        </div>
    )
}
export default RemoveCard;