import React from "react";
import { Link } from "react-router-dom";
import './TitleWithBackButton.css';

function TitleWithBackButton(props) {
    function getTitleClassName() {
        if(props?.backto) {
            return "d-flex justify-content-md-center justify-content-lg-center fw-bold pb-3 pt-3"
        }
        else {
            return "d-flex justify-content-md-center justify-content-lg-center fw-bold pb-3"
        }
    }
    return (
        <div className="TitleWithBackButton">
            {
                props?.backto ?
                <Link to={props?.backto} className="fs-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-chevron-left">
                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
                </svg>Back</Link>
                : ""
            }
            <h3 className={getTitleClassName()}>{props.title}</h3>
        </div>
    )
}
export default TitleWithBackButton;