import React, { useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { apiCall } from "../../helpers/ApiHelper";
import { checkIsFieldBlank } from "../../helpers/ValidationHelper";
import LoadingScreen from "../LoadingScreen";
import TitleWithBackButton from "../TitleWithBackButton";
import './form.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

function ChangePassword() {
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;

    const [showPassword, setShowPassword] = useState(false);
    const [showReNewPassword, setShowReNewPassword] = useState(false);

    const [input, setInput] = useState({})
    const [loading, setLoading] = useState(false)
    const [submitButton, setSubmitButton] = useState(true)
    const [fieldBlankError, setFieldBlankError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [rePasswordError, setRePasswordError] = useState(false)
    const [commonError, setCommonError] = useState(false)
    const [success, setSuccess] = useState(false)
    const {state} = useLocation();
    const manjalink_id = state?.manjalink_id;
    // console.log(manjalink_id)
    const setField = (field, value) => {
        setInput({
            ...input,
            [field]: value
        })
        if(submitButton == true) {
            setSubmitButton(false)
        }
    }
    // console.log(input)
    const saveChanges = () => {
        const isFieldBlank = checkIsFieldBlank([input.CurrentPw, input.NewPw, input.ReNewPw]);
        if(isFieldBlank == false && manjalink_id != null) {
            setFieldBlankError(false)
            setPasswordError(false)
            setCommonError(false)
            setSuccess(false)
            setRePasswordError(false)

            if(input.NewPw === input.ReNewPw) {
                setLoading(true);
                const requestOptions = {
                    body: JSON.stringify({ 
                        "ManjalinkID": manjalink_id,
                        "CurrentPassword": input.CurrentPw,
                        "NewPassword": input.NewPw,
                        "ReNewPassword": input.ReNewPw
                    })
                  }
                // console.log(requestOptions)
                apiCall('POST', SERVER_URL, 'ManjaProfile/UpdatePassword', requestOptions)
                .then(result => {
                    // console.log(result)
                    if(result) {
                        if(result.ok) {
                            // console.log(result.status)
                            setSuccess(true)
                            setInput({})
                        }
                        else {
                            // console.log(result?.error)
                            // console.log(result?.error?.status == ('401'))
                            if(result?.error?.status == ('401')) {
                                setPasswordError(true)
                                setInput({})
                            }
                            else {
                                setCommonError(true)
                                setInput({})
                            }
                        }
                        setLoading(false);
                    }
                })
            }
            else {
                setRePasswordError(true)
            }
        }
        else {
            setFieldBlankError(true)
        }
    }

    if(loading) return (<LoadingScreen /> )
    return(
        <Container>
            <TitleWithBackButton 
            backto='/myAccount'
            title='Change Password'
            />
            <div className="form-container m-auto mw-800">
                <Form>
                    <Form.Label className="font-sub-title">Current Password<span className='red'> *</span></Form.Label>
                    <Form.Control type='password' onChange = {e=>setField('CurrentPw', e.target.value)} required></Form.Control>
                    {/* <Form.Label className="font-sub-title">New Password<span className='red'> *</span></Form.Label>
                    <Form.Control type='password' onChange = {e=>setField('NewPw', e.target.value)} required></Form.Control>
                    <Form.Label className="font-sub-title">Re-enter New Password<span className='red'> *</span></Form.Label>
                    <Form.Control type='password' onChange = {e=>setField('ReNewPw', e.target.value)} required></Form.Control> */}

                    <Row>
                        <Col className="position-relative">
                            <Form.Label className="font-sub-title">New Password<span className='red'> *</span></Form.Label>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                onChange={e => setField('NewPw', e.target.value)}
                                required
                                className="password-input"
                            />
                            <div
                                className="password-toggle-icon1"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="position-relative">
                            <Form.Label className="font-sub-title">Re-enter New Password<span className='red'> *</span></Form.Label>
                            <Form.Control
                                type={showReNewPassword  ? 'text' : 'password'}
                                onChange={e => setField('ReNewPw', e.target.value)}
                                required
                                className="password-input"
                            />
                            <div
                                className="password-toggle-icon1"
                                onClick={() => setShowReNewPassword(!showReNewPassword)}
                            >
                                {showReNewPassword  ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="mw-800 m-auto mt-2 submit-button-position">
                <button className="btn btn-primary save-button" type="button" disabled={submitButton} onClick={saveChanges}>Save Changes</button>
                {
                    fieldBlankError ? <label className="form-label label-danger">Please fill in all required field.</label>
                    : passwordError ? <label className="form-label label-danger">Your current password does not matches with the password you provided.</label>
                    : commonError ? <label className="form-label label-danger">Update failed, please try again later.</label>
                    : rePasswordError ? <label className="form-label label-danger">Your new password does not matches with the re-enter new password you provided.</label>
                    : success ? <label className="form-label label-success">Password successfully updated!</label>
                    :""
                }
            </div>
        </Container>
    )
}
export default ChangePassword;