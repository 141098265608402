export function changeDateFormatToYYYYMMDD(d) {
    // // console.log(date)
    const date = new Date(d)
    let day = date.getDate();
    if(day<10) {
        day = '0'+day;
    }
    let month = date.getMonth() + 1;
    if(month<10) {
        month = '0'+month;
    }
    let year = date.getFullYear();
    return year + "-" + month + "-" + day;
}

export function changeDateFormatToDDMMMYYYY(date) {
    const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let myDate = new Date(date);
    let day = myDate.getDate();
    let month = months[myDate.getMonth()];
    let year = myDate.getFullYear();
    return day + " " + month + " " + year;
}

export function changeDateTimeFormatToDDMMMYYYY(datetime) {
    if(datetime) {
        const datetimeArr = datetime.split(" ");
        const date = datetimeArr[0]
        const time = datetimeArr[1] + " " + datetimeArr[2]
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        let myDate = new Date(date);
        let day = myDate.getDate();
        let month = months[myDate.getMonth()];
        let year = myDate.getFullYear();
        return day + " " + month + " " + year + ", "+ time;
    }
    return datetime;
}

export function changeDateTimeDDMMYYYYFormatToDDMMMYYYY(datetime) {
    // "16/06/2023 14:35:42"
    if(datetime) {
        const datetimeArr = datetime.split(" ");
        const date = datetimeArr[0]
        const time = datetimeArr[1]
        const ampm = datetimeArr[2]
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const dateArr = date.split("/");
        let myDate = new Date(dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0]);
        // console.log(myDate);
        let day = myDate.getDate();
        let month = months[myDate.getMonth()];
        let year = myDate.getFullYear();
        return day + " " + month + " " + year + ", "+ time + " " + ampm;//convertTimeToAMPM(time);
    }
    return datetime;
}

//timestamp example: 7/29/2015 10:57:00 AM
export function convertTimeStampDateToYYYYMMDD(timestamp) {
    if(timestamp) {
        const timestampArr = timestamp.split(" ");
        const date = timestampArr[0];
        const time = timestampArr[1];
        const ampm = timestampArr[2];
        return changeDateFormatToYYYYMMDD(date) + " " + time + " " + ampm;
    }
    return timestamp
}

//time sample 10:30:22
export function convertTimeToAMPM(time) {
    if(time && time.includes(":")) {
        const timeArr = time.split(":");
        const date = new Date();
        date.setHours(timeArr[0]);
        date.setMinutes(timeArr[1]);
        date.setSeconds(timeArr[2]??"");
        
        const formattedTime = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        
        // console.log(formattedTime); // Output: 10:30:22 AM
        return formattedTime
    }
    return time;
}

//timestamp example: 2015-07-09 10:57:20
export function convertTimeStampTimeToAMPM(timestamp) {
    if(timestamp) {
        const timestampArr = timestamp.split(" ");
        return timestampArr[0] + " " + convertTimeToAMPM(timestampArr[1])
    }
    return timestamp
}

export function checkIsTerminatedDate(date) {
    if(date) {
        const d = new Date(date);
        // console.log(d)
        if(d.getFullYear() <= 1900) {
            return true;
        }
    }
    return false;
}