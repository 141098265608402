import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { apiCall } from "../helpers/ApiHelper";
import { hideSensitiveDataFront, hideSensitiveDataMiddle } from "../helpers/DataHelper";
import { changeDateFormatToDDMMMYYYY } from "../helpers/DateHelper";
import { checkIsManjaPay } from "../helpers/ValidationHelper";
import LoadingScreen from "./LoadingScreen";
import './MyAccount.css';
import MyCwlExpressLoyalty from "./MyCwlExpressLoyalty";
import MyManjaLinkCard from "./MyManjaLinkCard";
import MyManjaPay from "./MyManjaPay";

function MyAccount(props) {

    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const MANJA_TERMINAL = process.env.REACT_APP_TERMINAL;
    const MANJA_PROFILE_IMAGE_URL = process.env.REACT_APP_MANJA_PROFILE_IMAGE_URL;
    const CWL_MANJA_REWARD_URL = process.env.REACT_APP_CWL_MANJA_REWARD_URL;
    const CWL_MANJA_API_KEY = process.env.REACT_APP_CWL_MANJA_REWARD_KEY;
    
    const user_email = props?.email;
    const user_key = props?.user_key;
    const [imageError, setImageError] = useState(false);

    const [userData, setUserData] = useState(null);
    const [loyaltyData, setLoyaltyData] = useState(null);
    const [manjalinkCard, setManjalinkCard] = useState(null);
    const [manjaPay, setManjaPay] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    // const [base64, setBase64] = useState(null);
    const [profilePic, setProfilePic] = useState(`../../public/assets/img/profilepic_placeholder.png`);
    // console.log(props)
    // console.log(user_email)
    // console.log(user_key)
    // console.log(loyaltyData)

    useEffect(()=>{
        if(user_email != null && user_key != null) {
            apiCall('GET', SERVER_URL, `ManjaProfile/SelectManjaProfileByEmail/${user_email}/${user_key}`)
            .then(result => {
                if(result != null) {
                    setUserData(result[0]);
                    // console.log(result[0])
                    if(result[0].profile_pic != null && result[0].profile_pic != "") {
                        setProfilePic(`url(${result[0]?.image_file}) top / cover no-repeat`)
                        // setBase64(`data:image/jpg;base64,${result[0]?.image_file}`)
                    }
                    // manjalink id example: 68902 , normal: result[0].manjalink_id
                    apiCall('GET', SERVER_URL, `Manjalink/SelectCardList/${result[0].manjalink_id}/${MANJA_TERMINAL}`)
                    .then(cardResult => {
                        if(cardResult != null) {
                            let tmp_manjapay = [];
                            let tmp_manjalink = [];
                            cardResult.forEach(element => {
                                if(checkIsManjaPay(element.printed_serial) == true) {
                                    tmp_manjapay.push(element);
                                }
                                else {
                                    tmp_manjalink.push(element);
                                }
                            });
                            // console.log(tmp_manjapay)
                            setManjaPay(tmp_manjapay)
                            setManjalinkCard(tmp_manjalink)
                        }
                        // console.log(cardResult)
                        setLoading(false);
                    })
                }
                else {
                    setError('Unable to retrieve user data');
                    // console.log('Unable to retrieve user data');
                    setLoading(false);
                }
            })

            const requestOptions = {
                headers: {
                    apikey: CWL_MANJA_API_KEY
                }
            }
            // ${user_email}
            apiCall('GET', CWL_MANJA_REWARD_URL, `Transactions/1/email/${user_email}`, requestOptions)
            .then(result => {
                // console.log(result)
                if(result) {
                    if(result?.status?.errorcode != '404') {
                        // have loyalty card
                        // console.log("ok")
                        if(result?.results && result?.results.length > 0) {
                            // have loyalty transaction data
                            // console.log("have loyalty transaction data")
                            setLoyaltyData(result.results)
                        }
                        else {
                            // console.log("no loyalty transaction data")
                        }
                    }
                    else {
                        // console.log("no loyalty card")
                    }
                }
                else {
                    // console.log("error")
                }
            })

        }
    },[])
    
    // console.log(userData)
    // console.log(error)

    const editProfile = () => {
        window.location.href = '/editProfile'
    }

    const changeImage = (e) => {
        setImageError(false)
        // console.log(e.target.files[0])
        // const reader = new FileReader();
        // reader.readAsDataURL(e.target.files[0]);
        const data = new FormData()
        data.append("ProfilePic", e.target.files[0], e.target.files[0].name)
        data.append("ManjaLinkID", userData.manjalink_id)
        // reader.append("ProfilePic", e.target.files[0]);
        // console.log(data)
        for (var key of data.entries()) {
			// console.log(key[0] + ', ' + key[1])
		}
        // const request = new XMLHttpRequest();
        // request.open("POST", `${SERVER_URL}ManjaProfile/UploadProfilePicture`);
        // request.send(data);
        if(e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/png") {
            const requestOptions = {
                // body: JSON.stringify({
                //     ManjaLinkID: userData.manjalink_id,
                //     // formData
                // })
                headers: {},
                body: data
            }
            apiCall('POST', SERVER_URL, 'ManjaProfile/UploadProfilePicture', requestOptions)
            .then(response => {
                // console.log(response)
                if(response.status == '200') {
                    window.location.reload();
                }
                else {
                    setImageError(true)
                }
            })

        }
        else {
            setImageError(true)
        }

    }

    if (loading) return <LoadingScreen />;
    return (
        <>
        <Container>
            <div className="bw-0">
                <label className="form-label fw-bold">My Account</label>
                {/* <img src={base64} /> */}
            </div>
            <div className="my-account-container">
                <Row>
                    <Col sm={12} md={4} lg={2}>
                        <div className="pb-4 mt-1">
                            <div id="profile-pic" className="d-flex align-items-end my-account-profile-pic" 
                            style={{background: `${profilePic}`}}>
                                <input type="file" accept="image/*" id="uploadProfilePic" onChange={changeImage} />
                                <label for='uploadProfilePic' className="btn btn-primary my-account-profile-edit" >
                                    Edit
                                </label>
                            </div>
                            {
                                imageError ? <label className="form-label label-danger">Only jpeg/png file is allowed.</label>
                                : ""
                            }
                        </div>
                    </Col>
                    <Col sm={12} md={8} lg={10}>
                        <Row>
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <h5>{userData?.full_name}
                                            <Link to="/editProfile" state={{userData}}><i className="far fa-edit ps-1 font-primary-success pointer" ></i></Link>
                                        </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label fw-bold font-sub-title">Email</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label font-info">{hideSensitiveDataMiddle(userData?.email)}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label fw-bold font-sub-title">Gender</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label font-info">{userData?.gender != '' ? userData?.gender == 'M' ? 'Male' : 'Female' : '-'}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label fw-bold font-sub-title">IC / Passport No.</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label font-info">{userData?.ic_number != '' ? hideSensitiveDataFront(userData?.ic_number) : '-' ?? '-'}</label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6} md={6}>
                                <div className="height32"></div>
                                <Row>
                                    <Col>
                                        <label className="col-form-label fw-bold font-sub-title">Date of Birth</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label font-info">{userData?.dob != '' ? changeDateFormatToDDMMMYYYY(userData?.dob) : '-'}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label fw-bold font-sub-title">Contact No.</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="col-form-label font-info">{userData?.mobile != '' ? hideSensitiveDataFront(userData?.mobile) : '-'}</label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={3} className="p-1">
                            <Link to="/changePassword" state={{manjalink_id: userData?.manjalink_id}}>
                                <button className="btn btn-primary primary-button-full-width font-sub-title" type="button">
                                    Change Password
                                </button>
                            </Link>
                            </Col>
                            <Col sm={12} md={12} lg={3} className="p-1">
                            <Link to="/manageSubscription" state={{manjalink_id: userData?.manjalink_id}}>
                                <button className="btn btn-primary primary-button-full-width font-sub-title" type="button">
                                    Manage Subscription
                                </button>   
                            </Link>                     
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>
            </div>
        </Container>
        <br />
        <MyManjaLinkCard manjalinkCard={manjalinkCard} manjalink_id={userData?.manjalink_id} userData={userData} />
        <br />
        {
            manjaPay != null ?
            <MyManjaPay manjaPay={manjaPay} userData={userData} />
            : ""
        }
        <br />
        {
            loyaltyData != null ?
            <MyCwlExpressLoyalty email={user_email} loyaltyData={loyaltyData} />
            : ""
        }
        <br />
        </>
    )
}
export default MyAccount;