import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { apiCall } from "../../helpers/ApiHelper";
import { checkIsFieldBlank } from "../../helpers/ValidationHelper";
import SingleCardInfo from "../SingleCardInfo";
import TitleWithBackButton from "../TitleWithBackButton";
import parse from 'html-react-parser';
import './form.css';

function LostOrCancelCard(props) {
    const { state } = useLocation();
    const manjalink_id = state?.manjalink_id;
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;

    const [input, setInput] = useState({
        CountryCode: "MY",
        checkbox1: false,
        checkbox2: false
    })

    const [submitButton, setSubmitButton] = useState(true);
    const [fieldBlankError, setFieldBlankError] = useState(false);
    const [failedWithErrMsg, setFailedWithErrMsg] = useState(null);
    const [commonError, setCommonError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [checkBoxError, setCheckBoxError] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    // Function to update the state based on the dropdown selection
    const handleDropdownChange = (e) => {
        setSelectedOption(e.target.value);
        setField('Remark', e.target.value);
    };


    const setField = (field, value) => {
        setInput({
            ...input,
            [field]: value
        })
        if (submitButton == true) {
            setSubmitButton(false)
        }
    }
    // console.log(input)
    // console.log(state)

    const submitCancelCard = () => {
        setSubmitButton(true)
        setFieldBlankError(false)
        setCheckBoxError(false)
        setCommonError(false)
        setFailedWithErrMsg(null)
        setSuccess(false)
        // console.log(input)
        const isFieldBlank = checkIsFieldBlank(
            [
                manjalink_id,
                state?.printed_serial,
                input.Name,
                input.ContactNo,
                input.BankAccNo,
                input.BankName,
                input.CountryCode,
                input.Remark,
            ]
        )
        // console.log(isFieldBlank)
        let checkBoxChecking = false;
        if (input.checkbox1 && input.checkbox2) {
            checkBoxChecking = true;
        }
        if (checkBoxChecking) {
            if (isFieldBlank === false) {
                const requestOptions = {
                    body: JSON.stringify({
                        "ManjaLinkID": manjalink_id,
                        "PrintedSerial": state?.printed_serial,
                        "Name": input.Name,
                        "ContactNo": input.ContactNo,
                        "FinalAmt": "0", //set to zero, the final amt should get from CCCS to prevent any unnecessary fraud
                        "BankAccNo": input.BankAccNo,
                        "BankName": input.BankName,
                        "CountryCode": input.CountryCode,
                        "ReasonID": "4",
                        "Remark": input.Remark
                    })
                }

                // console.log(requestOptions);


                apiCall('POST', SERVER_URL, "Manjalink/TerminateCard", requestOptions)
                    .then(response => {
                        // console.log(response)
                        if (response.ok) {
                            setSuccess(true)
                            setSubmitButton(false)
                        }
                        else {
                            if (response?.error?.message && response?.error?.message != "") {
                                setFailedWithErrMsg(response?.error?.message)
                                setSubmitButton(false)
                            }
                            else {
                                setCommonError(true)
                                setSubmitButton(false)
                            }
                        }
                    })


            }
            else {
                // set Error
                setFieldBlankError(true)
                setSubmitButton(false)
            }
        }
        else {
            setCheckBoxError(true)
            setSubmitButton(false)
        }
    }
    return (
        <Container>
            <TitleWithBackButton
                backto='/myAccount'
                title='Lost / Cancel ManjaLink'
            />

            <SingleCardInfo
                cu_id={state.cu_id}
                printed_serial={state.printed_serial}
                design_images={state.design_images}
                current_balance={state.current_balance}
                rebate_amt={state.rebate_amt}
                card_exp_date={state.card_exp_date}
            />

            <div className="form-container mw-800 m-auto">
                <Form>
                    <Form.Label className="font-sub-title-large">Full Name ( as per IC/passport )<span className='red'> *</span></Form.Label>
                    <Form.Control className="font-sub-title-large" type='text' required onChange={e => setField('Name', e.target.value)}></Form.Control>
                    <Form.Label className="font-sub-title-large">Contact Number<span className='red'> *</span></Form.Label>
                    <Form.Control className="font-sub-title-large" type='text' maxLength={15} required onChange={e => setField('ContactNo', e.target.value)}></Form.Control>

                    <Row>
                        <Col md={6}>
                            <Form.Label className="font-sub-title-large">Bank Country<span className='red'> *</span></Form.Label>
                            <Row>
                                <Col>
                                    <Form.Check defaultChecked onChange={e => setField('CountryCode', 'MY')} className="me-5 font-sub-title-large" inline label="Malaysia" name="CountryCode" type='radio' id={`formCheck-1`} />
                                    <Form.Check onChange={e => setField('CountryCode', 'SG')} className="font-sub-title-large" inline label="Singapore" name="CountryCode" type='radio' id={`formCheck-2`} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Form.Label className="font-sub-title-large">Bank Name<span className='red'> *</span></Form.Label>
                            <Form.Select className="font-sub-title-large" onChange={e => setField('BankName', e.target.value)}>
                                <option value="" selected>Please Select a Bank</option>
                                {
                                    props?.bankList != null ?
                                        props.bankList && props.bankList.map(({
                                            bank_id,
                                            bank_name,
                                            country_code
                                        }, index) => (
                                            <option value={bank_name}>{bank_name}</option>
                                        ))
                                        : ""
                                }
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label className="font-sub-title-large">Bank Account Number<span className='red'> *</span></Form.Label>
                            <Form.Control className="font-sub-title-large" type='text' onChange={e => setField('BankAccNo', e.target.value)}></Form.Control>
                        </Col>
                        <Col>
                            <Form.Label className="font-sub-title-large">Bank Account Holder Name</Form.Label>
                            <Form.Control className="font-sub-title-large" type='text' disabled value={input.Name}></Form.Control>
                        </Col>
                    </Row>

                    <Form.Label className="font-sub-title-large">Reason for cancellation<span className='red'> *</span></Form.Label>

                    <Form.Select onChange={handleDropdownChange}
                        value={selectedOption}>
                        <option value="" selected="">Please specify your reason</option>
                        <option value="Card lost">Card lost</option>
                        <option value="Card expired">Card expired</option>
                        <option value="No longer in use">No longer in use</option>
                        <option value="Card malfunction">Card malfunction</option>
                        <option value="Others">Others</option>
                    </Form.Select>


                    {/* Conditionally render this input field when "Others" is selected */}
                    {selectedOption === 'Others' && (
                        <Form.Control
                            className="font-sub-title-large mt-2"
                            type='text'
                            onChange={(e) => setField('Remark', e.target.value)}
                            placeholder="Please specify your reason"
                        />
                    )}



                    <Form.Check onChange={e => setField('checkbox1', e.target.checked)} inline label="I acknowledged that my full name is as per NRIC and all the
                        information that I submitted is true, accurate, current and
                        complete, otherwise the refund will not be processed. I understand
                        that the remaining value in card will be credited to my bank
                        account within 45 working days from the date of submitting the
                        refund application form. A handling fee of RM10(inclusive of 6%
                        SST) will be deducted from the value of the card."
                        name="ack1" type='checkbox' id={`formCheck-3`} className="mt-2 font-sub-title-large" />
                    <Form.Check onChange={e => setField('checkbox2', e.target.checked)} inline label="By submitting this form, I acknowledged that I have read the terms
                        &amp; conditions. Your personal data will be stored securely and
                        will be used for the purposes of processing the refund. Full
                        details are given in our Privacy Policy."
                        name="ack2" type='checkbox' id={`formCheck-4`} className="mt-2 font-sub-title-large" />

                </Form>
                {
                    fieldBlankError ? <label className="form-label label-danger">Please fill in all required field.</label>
                        : commonError ? <label className="form-label label-danger">System is failed to send out your request.</label>
                            : checkBoxError ? <label className="form-label label-danger">Please read and tick our policy above before submit.</label>
                                : failedWithErrMsg ? <label className="form-label label-danger">{parse(failedWithErrMsg.replace(/"/g, ""))}</label>
                                    : success ? <label className="form-label label-success">We have received your request.  You will be informed about such successful transfer by phone call or email within 45 working days from now.  You may proceed to any of our Causeway Link counters to purchase a new ManjaLink card for your daily travel.  If you require further assistance, please contact our customer call center at +607-3602244 or email to <a href='mailto:feedback@manjalink.com.my'>feedback@manjalink.com.my</a></label>
                                        : ""
                }
            </div>
            <div className="mw-800 m-auto submit-button-position mt-2">
                <button
                    className="btn btn-primary save-button"
                    type="button"
                    disabled={submitButton}
                    onClick={() => submitCancelCard()}
                >
                    Submit
                </button>
            </div>
        </Container>
    );
}
export default LostOrCancelCard;