import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AddCard from './forms/AddCard';
import RemoveCard from './forms/RemoveCard';
import DisputeForm from './forms/DisputeForm';
import { useState } from 'react';
import Nickname from './forms/Nickname';

function ModalVertical(props) {
  const [page, setPage] = useState(1);
  // console.log(page)
  const checkTitle = (title) => {
    if(title) {
      if(title == "Transaction Details") {
        if(page == 1) {
          return title;
        }
        else {
          return "Dispute Form";
        }
      }
      else {
        return title;
      }
    }
  }
  let title = checkTitle(props?.title);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show
      centered
    >
      <Modal.Header className='ps-5 pt-4 pe-5 b-none column-reverse' closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            <h2 className={`${props?.class} m-auto`}>{title}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='ps-5 pe-5 pb-4'>
        {
            props.title == "Add New Card" ? <AddCard onHide={props?.onHide} manjalink_id={props?.manjalink_id}/> : 
            props.title == "Remove Card" ? <RemoveCard onHide={props?.onHide} printed_serial={props?.printed_serial} manjalink_id={props?.manjalink_id} /> : 
            props.title == "Transaction Details" ? <DisputeForm onHide={props?.onHide} page={page} setPage={()=>setPage(2)} selectedReportModal={props?.selectedReportModal} /> : 
            props.title == "Add a Nickname" ? <Nickname onHide={props?.onHide} manjalink_id={props?.manjalink_id} printed_serial={props?.printed_serial} /> :
            ""
        }
      </Modal.Body>
    </Modal>
  );
}
export default ModalVertical;