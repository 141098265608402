import React, { useEffect, useState } from "react";
import TransactionHistory from "../components/TransactionHistory";
import { useLocation } from "react-router-dom";
import { apiCall } from "../helpers/ApiHelper";
import LoadingScreen from "../components/LoadingScreen";

function TransactionHistoryPage() {
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const MANJA_TRANS_ID = process.env.REACT_APP_TRANS_ID;
    const { state } = useLocation();
    const [transHistory, setTransHistory] = useState(null);
    const [loading, setLoading] = useState(true);
    // console.log(state.cu_id)
    let header = ['','Transaction','Description','Travel Credit','Details'];
    if(state?.title == 'loyalty') {
        header = ['Date','Description','Point In/Out', 'Point', 'Point Expiry Date'];
    }
    // cu_id
    // design_images
    // current_balance
    // card_exp_date
    useEffect(()=>{
        if(state.loyaltyData == null) {
            apiCall('GET', SERVER_URL, `Manjalink/SelectCardTransactionHistory/${state.cu_id}/${MANJA_TRANS_ID}`)
            .then(result => {
                // console.log(result)
                setTransHistory(result)
                setLoading(false)
            })
        }
        else {
            setLoading(false)
        }
    },[])
    // call api to fetch trans history
    if (loading) return <LoadingScreen />;
    return (
        <TransactionHistory 
        cu_id={state?.cu_id} 
        printed_serial={state?.printed_serial} 
        rebate_amt={state?.rebate_amt} 
        design_images={state?.design_images} 
        current_balance={state?.current_balance != null ? state?.current_balance : state?.loyaltyData?.balancePoints} 
        card_exp_date={state?.card_exp_date} 
        transHistory={transHistory != null ? transHistory : state?.loyaltyData}
        currentDate = {state?.currentDate}
        title = {state?.title}
        loyaltyPoints = {state?.loyaltyPoints}
        manjalink_id = {state?.manjalink_id}
        userData = {state?.userData}
        header={header}
        card_is_cancelled={state?.card_is_cancelled}
        />
    )
}
export default TransactionHistoryPage;