import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CardCarousel from "./CardCarousel";
import ManjaCard from "./ManjaCard";
import ModalVertical from "./ModalVertical";

function MyManjaLinkCard(props) {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showNickname, setShowNickname] = useState(false);
    const [printed_serialCallback, setPrinted_serialCallback] = useState(null);
    const cardData = props?.manjalinkCard;
    const manjalink_id = props?.manjalink_id;
    // console.log(cardData)
    // console.log(printed_serialCallback)

    return (
        <>
        {
            show ? 
            <ModalVertical 
            title="Add New Card"
            class="primary-heading-color"
            onHide={()=>setShow(false)}
            manjalink_id={manjalink_id}
            /> 
            : ""
        }
        {
            show2 ? 
            <ModalVertical 
            title="Remove Card"
            class="primary-heading-color"
            onHide={()=>setShow2(false)}
            manjalink_id={manjalink_id}
            printed_serial={printed_serialCallback}
            /> 
            : ""
        }
        {
            showNickname ?
            <ModalVertical
            title="Add a Nickname"
            class="primary-heading-color"
            onHide={()=>setShowNickname(false)}
            manjalink_id={manjalink_id}
            printed_serial={printed_serialCallback}
            />
            : ""
        }
        <Container>
            <div className="grid">
                <div className="bw-0">
                    <label className="form-label fw-bold">My ManjaLink Card</label>
                    <button className="btn btn-primary float-end add-card-button mb-3" type="button" onClick={()=>setShow(true)}>
                        <i className="fas fa-plus pe-1"></i>
                    New Card
                    </button>
                </div>
            </div>
            
            {
                cardData == null || cardData?.length == 0 ?
                <Row>
                    <Col sm={12}>
                        <div>
                            <img className="d-inline-block no-card-found-img" src="assets/img/member_nocard.png" />
                            <h6 className="d-inline-block ps-2">No Card Found</h6>
                        </div>
                    </Col>
                </Row>
                : 
                <CardCarousel 
                    manjalink_id={manjalink_id}
                    userData={props?.userData}
                    cardData = {cardData}
                    show = {()=>setShow2(true)}
                    onHide={()=>setShow2(false)}
                    showNickname={()=>setShowNickname(true)}
                    onHideNickname={()=>setShowNickname(false)}
                    printed_serialCallback={setPrinted_serialCallback}
                />
            }
        </Container>
        </>
    )
}
export default MyManjaLinkCard;