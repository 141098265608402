import { capitalizeWords } from "../../helpers/DataHelper";

function DisputeStatus(props) {
    const rejectReason = props?.disputeRejectReason;
    return (
        <div className="disputeStatus p-4">
            <h3>Status of Disputes</h3>
            <h6>Dispute Status: <strong>{capitalizeWords(props?.disputeStatus) ?? "-"}</strong></h6>
            {
                rejectReason ?
                <h6>Reject Reason: <strong>{rejectReason ?? "-"}</strong></h6>
                : 
                ""
            }
            <h6>Dispute Date: <strong>{props?.disputeDate ?? "-"}</strong></h6>
            {
                props?.disputeStatus === "APPROVED" || props?.disputeStatus === "PENDING REFUND" ?
                <h6>Amount: <strong>{props?.disputeAmount == "" ? "-" : "RM " + props?.disputeAmount}</strong></h6>
                : ""
            }
            {
                props?.disputeStatus === "APPROVED" &&
                <p className="pt-3 text-muted fw-bold">Please tap your card on the bus or self-service kiosks to transfer to credits.</p>
            }
        </div>
    )
}
export default DisputeStatus;