export function setTokenExpiry(tokenExpiry) {
    if(tokenExpiry != null) {
        sessionStorage.setItem("TokenExpiry",JSON.stringify(tokenExpiry))
    }
}

export function getTokenExpiry() {
    if(sessionStorage.getItem("TokenExpiry") != null) {
        return JSON.parse(sessionStorage.getItem("TokenExpiry"))
    }
}

export function setLoginStatus(LoginStatus) {
    if(LoginStatus != null) {
        sessionStorage.setItem("LoginStatus",JSON.stringify(LoginStatus))
    }
}

export function getLoginStatus() {
    if(sessionStorage.getItem("LoginStatus") != null) {
        return JSON.parse(sessionStorage.getItem("LoginStatus"))
    }
}

export function setUserName(UserName) {
    if(UserName != null) {
        sessionStorage.setItem("UserName",JSON.stringify(UserName))
    }
}

export function getUserName() {
    if(sessionStorage.getItem("UserName") != null) {
        return JSON.parse(sessionStorage.getItem("UserName"))
    }
}

export function setPrimaryEmail(email) {
    if(email != null) {
        sessionStorage.setItem("UserPrimaryEmail",JSON.stringify(email))
    }
}

export function getPrimaryEmail() {
    if(sessionStorage.getItem("UserPrimaryEmail") != null) {
        return JSON.parse(sessionStorage.getItem("UserPrimaryEmail"))
    }
}

export function setDiversifyKey(DiversifyKey) {
    if(DiversifyKey != null) {
        sessionStorage.setItem("DiversifyKey",JSON.stringify(DiversifyKey))
    }
}

export function getDiversifyKey() {
    if(sessionStorage.getItem("DiversifyKey") != null) {
        return JSON.parse(sessionStorage.getItem("DiversifyKey"))
    }
}

export function setRegisterStatus(RegisterStatus) {
    if(RegisterStatus != null) {
        sessionStorage.setItem("RegisterStatus",JSON.stringify(RegisterStatus))
    }
}

export function getRegisterStatus() {
    if(sessionStorage.getItem("RegisterStatus") != null) {
        return JSON.parse(sessionStorage.getItem("RegisterStatus"))
    }
}

export function clearRegisterStatus() {
    sessionStorage.removeItem("RegisterStatus")
}