import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ActivationResult.css";

function ActivationResult(props) {
    const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;
    if(props.result == 'success') {
        return (
            <Container>
                <div className='text-center'>
                    <img className="success_logo" src = 'assets/img/Manja_Logo-Rebrand.png' alt = 'img_success' />
                    <h5 className='font-primary-success'>Your account has been successfully activated.</h5>
                    <br />
                    Please click <Link to='/login'>here</Link> to log on.
                    <br />
                </div>
            </Container>
        )
    }
    else {
        return (
            <Container>
                <div className='text-center'>
                    <img src = 'assets/img/img_fail.png' alt = 'img_failed' />
                    <h5 className='red'>We've failed to activate your account.</h5>
    
                    It could be due to your account had been activated or given invalid activation code.<br />
                    Please click <Link to='/login'>here</Link> to log on.<br />
                    If the problem still persists, please contact +607-360 2244/ +6012-713 3244<br /> 
                    Email: <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                </div>
            </Container>
        )
    }
}
export default ActivationResult;