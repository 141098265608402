import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { apiCall } from "../../helpers/ApiHelper";
import { checkIsFieldBlank, emailFormat } from "../../helpers/ValidationHelper";
import HeaderBackgroundImage from "../HeaderBackgroundImage";
import './form.css';

function Register() {
    useEffect(()=>{
        sessionStorage.clear();
      },[])
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const emailRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const rePasswordRef = React.useRef(null);
    const subscribeRef = React.useRef(true);

    const [fieldBlankError, setFieldBlankError] = React.useState(false)
    const [emailError, setEmailError] = React.useState(false)
    const [emailUsedError, setEmailUsedError] = React.useState(false)
    const [rePasswordError, setRePasswordError] = React.useState(false)
    const [commonError, setCommonError] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [pending, setPending] = React.useState(false)

    const signUp = () => {
        // console.log(subscribeRef.current.checked);
        // console.log(emailRef.current.value)
        setFieldBlankError(false)
        setRePasswordError(false)
        setCommonError(false)
        setSuccess(false)
        setEmailError(false)
        setPending(false)
        setEmailUsedError(false)
        const isFieldBlank = checkIsFieldBlank([emailRef.current.value, passwordRef.current.value, rePasswordRef.current.value])
        if(!isFieldBlank) {
            const emailValidation = emailFormat(emailRef.current.value);
            if(emailValidation) {
                if(passwordRef.current.value === rePasswordRef.current.value) {
                    const requestOptions = {
                        body: JSON.stringify({
                            "Email": emailRef.current.value,
                            "Password": passwordRef.current.value,
                            "Subscribe": subscribeRef.current.checked == true ? 1 : 0,
                            "Source": "email"
                        })
                    }
                    apiCall('POST', SERVER_URL, 'ManjaProfile/MemberRegistration', requestOptions)
                    .then(response=>{
                        // console.log(response)
                        if(response) {
                            if (response.status == '206') {
                                setPending(true)
                            }
                            else if (response.status == '207') {
                                setEmailUsedError(true)
                            }
                            else if (response.status == '208') {
                                setSuccess(true)
                            }
                            else if (response.ok == true) {
                                setSuccess(true)
                            }
                            else {
                                setCommonError(true)
                            }
                        }
                    })
                }
                else {
                    setRePasswordError(true)
                }
            }
            else {
                setEmailError(true)
            }
        }
        else {
            setFieldBlankError(true)
        }
    }

    const sendVerification = () => {
        if(emailRef.current.value) {
            const requestOptions = {
                body: JSON.stringify({ 
                    Email: emailRef.current.value
                })
            }
            apiCall('POST', SERVER_URL, 'ManjaProfile/ResendVerificationEmail', requestOptions)
            .then(response=>{
                if(response.status == '204' || response.status == '208') {
                    alert(`Email successfully sent to ${emailRef.current.value}`);
                    window.location.href = '/login';
                }
                else if(response.status == '206') {
                    alert(`${emailRef.current.value} has been registered, please proceed to login`);
                    window.location.href = '/login';
                }
                else if(response.status == '404') {
                    alert(`${emailRef.current.value} is not a registered member, please proceed to make registration`);
                    window.location.href = '/register';
                }
                else {
                    alert("Failed to resend verification email, please try again later");
                }
            })
        }
    }

    return (
        <>
        <HeaderBackgroundImage />
        <Form>
            <div className="loginRegister">
                <div className="text-center bg-white">
                    <h4>Register</h4>            
                </div>
                <div className="loginRegister-form-container">
                    <div>
                        <Row>
                            <Col>
                                <Form.Label>Email</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control type='email' ref={emailRef}></Form.Control>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col>
                                <Form.Label>Create Password</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control type='password' ref={passwordRef}></Form.Control>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col>
                                <Form.Label>Re-enter Password</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control type='password' ref={rePasswordRef}></Form.Control>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Form.Check inline label="Subscribe for ManjaLink updates" 
                    name="checkbox" type='checkbox' id={`formCheck-1`} className="subscribe ps-5 pe-3" defaultChecked ref={subscribeRef} />
                {
                    fieldBlankError ? <p className="form-label label-danger ps-5 pe-5">Please fill in all required field.</p>
                    : emailError ? <p className="form-label label-danger ps-5 pe-5">Your email is incorrect format.</p>
                    : commonError ? <p className="form-label label-danger ps-5 pe-5">System is unable to register new user.</p>
                    : rePasswordError ? <p className="form-label label-danger ps-5 pe-5">Your password does not matches with the re-enter password you provided.</p>
                    : pending ? <div><p className="form-label label-danger ps-5 pe-5">Your email address is still pending verification. To complete the registration process, please check your inbox for a verification email and click the link to verify.<br /><br /><div className="text-center font-black"><p className="mb-0 inline">Didn't receive the email? </p><button type="button" className="transparent-button mb-3" onClick={sendVerification}>Send Again</button></div></p></div>
                    : emailUsedError ? <p className="form-label label-danger ps-5 pe-5">The email address has been registered, Please <Link to="/login" className="link-success">Login Here</Link>.</p>
                    : success ? <p className="form-label label-success ps-5 pe-5">The registration code has been sent to your email address. If not, please check your spam or junkmail.</p>
                    :""
                    
                }
                <div className="text-center mb-2">
                    <button className="btn btn-primary save-button" type="button" onClick={signUp}>REGISTER</button>
                </div>
                <div className="text-center mt-4">
                    <p className="inline">Have an account?&nbsp;</p>
                    <Link to="/login" className="link-success">
                        Login now!
                    </Link>
                </div>
            </div>
        </Form>
        </>
    )
}
export default Register;