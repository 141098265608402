import React, { useEffect, useState } from "react";
import OnlineReloadStep from "./OnlineReloadStep";
import OnlineTopUp from "./OnlineTopUp";
import SingleCardInfo from "./SingleCardInfo";
import TitleWithBackButton from "./TitleWithBackButton";
import './OnlineReload.css';
import ReloadCard from "./ReloadCard";
import { useLocation } from "react-router-dom";
import { apiCall } from "../helpers/ApiHelper";

function OnlineReload() {
	const [topUpMethod, setTopUpMethod] = useState(1);
	const [topUpAmount, setTopUpAmount] = useState(null);
	const [reloadPin, setReloadPin] = useState(null);
	const [topUpError, setTopUpError] = useState(false);
	const [reloadError, setReloadError] = useState(false);
	const [reloadSuccess, setReloadSuccess] = useState(false);
	const [topUpApiError, setTopUpApiError] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);
	const { state } = useLocation();
	const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
	// console.log(state)

	useEffect(() => {
		setReloadPin(null);
		setTopUpAmount(null);
	}, [topUpMethod])

	function insertDash(str) {
		str = str.replace(/(.{4})/g, '$1-');
		return str.slice(0, -1);
	}

	const changeMethod = (e, method) => {
		const el1 = document.getElementById(`method1`);
		const el2 = document.getElementById(`method2`);

		el1.classList.remove("reload-selected", "reload-not-selected");
		el2.classList.remove("reload-selected", "reload-not-selected");
		e.target.classList.add("reload-selected");
		if (method == 1) {
			el2.classList.add("reload-not-selected")
		}
		else {
			el1.classList.add("reload-not-selected")
		}
		setTopUpMethod(method);
	}
	const selectedCallBack = (amount) => {
		// console.log(amount)
		if (amount != null) {
			setTopUpAmount(amount);
		}
	}

	const reloadCardCallBack = (pin) => {
		// console.log(pin)
		if (pin != null) {
			setReloadPin(pin)
		}
	}

	// console.log(`top up: RM ${topUpAmount}`)
	const redirectToPaymentGateway = () => {
		setButtonDisable(true)
		// window.location.href = '/paymentAck'
		setReloadError(false)
		setTopUpError(false)
		setTopUpApiError(false)
		setReloadSuccess(false)
		if (topUpMethod == '1') {
			if (topUpAmount != null) {
				// go top up
				const requestOptions = {
					body: JSON.stringify({
						"Amount": topUpAmount,
						"CustName": state?.userData?.full_name,
						"CustEmail": state?.userData?.email,
						"CustPhone": state?.userData?.mobile,
						"Details": "Manja - Top Up",
						"CurrencyID": "MYR",
						"ManjalinkID": state?.userData?.manjalink_id,
						"PrintedSerial": state?.printed_serial
					})
				}
				// console.log(requestOptions)
				apiCall('POST', SERVER_URL, 'Manjalink/new', requestOptions)
					.then(response => {
						// console.log(response)
						if (response) {
							if (response?.api_result?.url) {
								window.location.href = response?.api_result?.url
							}
							else {
								setTopUpApiError(true)
							}
						}
						else {
							setTopUpApiError(true)
						}
						setButtonDisable(false)
					})

			}
			else {
				setTopUpError(true)
				setButtonDisable(false)
			}
			// console.log(topUpAmount)
		}
		else {
			if (reloadPin != null && reloadPin.length == 16 && reloadPin != "") {
				// go reload
				const finalPin = insertDash(reloadPin)
				// console.log("go reload")
				const requestOptions = {
					body: JSON.stringify({
						"VerificationCode": finalPin,
						"ManjalinkID": state?.userData?.manjalink_id,
						"PrintedSerial": state?.printed_serial
					})
				}
				// console.log(requestOptions)
				apiCall('POST', SERVER_URL, 'Manjalink/ReloadCardTopup', requestOptions)
					.then(response => {
						// console.log(response)
						if (response) {
							if (response?.api_result) {
								// success
								setReloadSuccess(true)
							}
							else {
								setReloadError(true)
							}
						}
						else {
							setReloadError(true)
						}
						setButtonDisable(false)
					})
			}
			else {
				// console.log("no reload")
				setReloadError(true)
				setButtonDisable(false)
			}
		}
		// console.log(reloadPin)
	}

	return (
		<>
			<div className="container">
				<TitleWithBackButton
					backto='/myAccount'
					title={state != null ? state.title == 'ManjaLink' ? 'Top Up ManjaLink' : 'Top Up ManjaPay' : 'Top Up ManjaLink'}
				/>

				<OnlineReloadStep
					step="1"
				/>

				<SingleCardInfo
					title={state.title}
					cu_id={state.cu_id}
					printed_serial={state.printed_serial}
					design_images={state.design_images}
					current_balance={state.current_balance}
					card_exp_date={state.card_exp_date}
					rebate_amt={state.rebate_amt}
				/>


				<div className="basic-container">
					<button id="method1" className="btn btn-primary reload-selected font-sub-title-large mb-3" type="button" onClick={(e) => changeMethod(e, 1)}>Online Top Up</button>
					<button id="method2" className="btn btn-primary reload-not-selected font-sub-title-large mb-3" type="button" onClick={(e) => changeMethod(e, 2)}>Reload Card</button>

					{
						topUpMethod == 1
							? <OnlineTopUp
								selected={selectedCallBack}
							/>
							: <ReloadCard
								reloadCard={reloadCardCallBack}
							/>
					}
					{
						topUpMethod == '1' && topUpError ? <label className="form-label label-danger">Please select Top Up amount.</label>
							: topUpMethod == '1' && topUpApiError ? <label className="form-label label-danger">Error while Top Up.</label>
								: topUpMethod == '2' && reloadError ? <label className="form-label label-danger">Invalid Activation Code.</label>
									: topUpMethod == '2' && reloadSuccess ? <label className="form-label label-success">Top Up successful. Please tap your card on the bus tapping machine at least 3 hours after the top-up, or alternatively, you may tap your card at any of our self-service machines for an immediate transfer of the travel credits.</label>
										: ""
					}
				</div>

				<div className="basic-container-borderless">
					{state.title !== 'ManjaLink' ? (
						<div className="bg-warning p-3 mb-3">
							<p className="mt-1 mb-1 font-sub-title fw-bold">Important Note</p>
							<p className="font-sub-title">ManjaPay is only applicable for myBAS Johor Bahru, SBST Melaka, and UiTM Lendu services and is not applicable for cross-border bus services.</p>
						</div>
					) : ''}
				</div>

				<div className="mw-800 m-auto submit-button-position mt-1" >
					<button className="btn btn-primary reload-selected" type="button" onClick={redirectToPaymentGateway} disabled={buttonDisable}>Next&nbsp;
						<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="bi bi-chevron-right">
							<path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
						</svg>
					</button>
				</div>
			</div>
		</>
	)
}
export default OnlineReload;