import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { apiCall } from "../../helpers/ApiHelper";
import { changeDateTimeDDMMYYYYFormatToDDMMMYYYY, changeDateTimeFormatToDDMMMYYYY, convertTimeStampTimeToAMPM } from "../../helpers/DateHelper";
import { checkIsFieldBlank, checkIsManjaPay, emailFormat } from "../../helpers/ValidationHelper";
import './form.css';
import * as CryptoJS from 'crypto-js'
import { getFileType, overchargeDisputable, overchargeStatusMessage } from "../../helpers/DataHelper";
import DisputeStatus from "./DisputeStatus";

function DisputeForm(props) {
	const freshdesk_URL = process.env.REACT_APP_FRESHDESK_API_URL;
	const freshdesk_APIKEY = process.env.REACT_APP_FRESHDESK_API_KEY;
	const freshdesk_product_id = parseInt(process.env.REACT_APP_FRESHDESK_PRODUCT_ID); // product manjalink: 151000043926

	const CEPATS_URL = process.env.REACT_APP_CEPATSWEBAPI_URL;
	const CEPATS_SIGNATURE = process.env.REACT_APP_CEPATS_SIGNATURE_KEY;
	const signature = props.selectedReportModal.trans_data.trans_id + CEPATS_SIGNATURE;
	const hash = CryptoJS.SHA256(signature);
	const hashString = hash.toString(CryptoJS.enc.Hex);

	// const mojo_user_id = process.env.REACT_APP_MOJO_USER_ID;
	console.log(props)
	const manjalink_id = props?.manjalink_id;
	const printed_serial = props?.printed_serial;
	const subjectRef = useRef(null);
	const messageRef = useRef(null);
	const nameRef = useRef(null);
	const contactRef = useRef(null);
	const emailRef = useRef(null);
	const boardingLocationRef = useRef(null);
	const destinationRef = useRef(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [disputeStatus, setDisputeStatus] = useState(null);
	const [disputeImage, setDisputeImage] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const statusRequestOptions = {
			body: JSON.stringify({
				"Trans_log_id": props.selectedReportModal.trans_data.trans_id, // Tapping id
				"Signature": hashString // Authorization purposes
			})
		}
		apiCall('POST', CEPATS_URL, `freshdesk/CheckDisputeStatus`, statusRequestOptions)
			.then(response => {
				// console.log(response);
				if (response) {
					if (response?.api_result?.List) {
						setDisputeStatus(response.api_result.List[0]);
						setLoading(false);
					}
					else {
						setDisputeStatus(null);
						setLoading(false);
					}
				}
				else {
					setDisputeStatus(null);
					setLoading(false);
				}
			})
	}, [])
	// console.log(errorMessage)
	const goToPage2 = () => {
		props?.setPage(2);
	}
	const disputeApiCall = () => {
		setErrorMessage(null);
		setButtonDisabled(true);
		// console.log(emailRef.current.value)
		// console.log(emailFormat(emailRef.current.value))
		if (!emailFormat(emailRef.current.value)) {
			setErrorMessage({
				emailError: "Please enter a valid email"
			});
			setButtonDisabled(false);
			return;
		}
		const isFieldBlank = checkIsFieldBlank([
			subjectRef.current.value,
			messageRef.current.value,
			nameRef.current.value,
			contactRef.current.value,
			emailRef.current.value,
			boardingLocationRef.current.value,
			destinationRef.current.value,
			props?.selectedReportModal
		]);


		if (!isFieldBlank) {

			const fileName = disputeImage ? "attachment" : null;
			const fileType = disputeImage ? getFileType(disputeImage.type) : null;
			const data = new FormData()
			if (disputeImage) {
				data.append("Attachment", disputeImage, fileName + fileType)
			}
			data.append("Trans_log_id", props.selectedReportModal.trans_data.trans_id)
			data.append("Signature", hashString)
			data.append("Call_from", "Manjalink Web")
			data.append("ManjalinkID", props.selectedReportModal.userData.manjalink_id)
			data.append("Customer_name", nameRef.current.value)
			data.append("Customer_contact_no", contactRef.current.value)
			data.append("Customer_ic", props.selectedReportModal.userData.ic_number)
			data.append("Customer_email", emailRef.current.value)
			data.append("Dispute_subject", subjectRef.current.value)
			data.append("Dispute_description", messageRef.current.value)

			data.append("Boarding_location", boardingLocationRef.current.value)
			data.append("Destination", destinationRef.current.value)
			data.append("Printed_serial", props.selectedReportModal.printed_serial)
			data.append("Transaction_timestamp", changeDateTimeFormatToDDMMMYYYY(props.selectedReportModal.trans_data.created_timestamp))
			data.append("Amount", props.selectedReportModal.trans_data.amount)
			data.append("Mode", props.selectedReportModal.trans_data.mode)
			data.append("Status", props.selectedReportModal.trans_data.status)
			data.append("Type", props.selectedReportModal.trans_data.type)
			const requestOptions = {
				headers: {
					// "Content-Type": "multipart/form-data"
				},
				body: data
			}



			console.log("Request");
			console.log("================");
			console.dir(boardingLocationRef.current.value);
			console.dir(destinationRef.current.value);

			// insert into freshdesk and cccs

			apiCall('POST', CEPATS_URL, `freshdesk/Dispute`, requestOptions)
				.then(response => {
					console.log(response)
					if (response.status == '200' && response.api_result) {
						if (response && response.status == '200') {
							alert("Report Submitted Successfully");
							setButtonDisabled(false);
							window.location.reload();
						}
					}
					else {
						const ReturnDesc = JSON.parse(response?.error?.message);
						alert(ReturnDesc?.ReturnDesc ?? "Failed to create dispute ticket");
						setButtonDisabled(false);
					}
				})



		}
		else {
			setErrorMessage({
				blankError: "Please fill in all fields"
			})
			setButtonDisabled(false);
		}
	}
	const saveImage = (e) => {
		// console.log(e.target.files[0])
		if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/png") {
			if (e.target.files[0].size < 5242880) { // maximum 5mb
				setDisputeImage(e.target.files[0]);
				setErrorMessage(null);
			}
			else {
				setDisputeImage(null);
				setErrorMessage({
					fileError: "Please do not exceed 5mb file"
				})
			}
		}
		else {
			setDisputeImage(null);
			setErrorMessage({
				fileError: "Only jpeg/png file is allowed"
			})
		}
	}
	if (props.page == 1) {

		console.log(`is card cancelled = ${props.selectedReportModal.card_is_cancelled}`);
		// console.dir(disputeStatus.Number_Of_Dispute);


		return (
			<div className="pop-out-modal dispute-form">
				{/* <h5 className="pb-2">Remove card will delete your transaction history permanently. <br/>Are you sure you want to remove card ID: {props.printed_serial}?</h5> */}
				<Form>
					<div className="text-center">
						<Form.Label><strong className="font-large">{props.selectedReportModal.trans_data.amount}</strong> TCR</Form.Label>
						<div>
							<Form.Label>{changeDateTimeFormatToDDMMMYYYY(props.selectedReportModal.trans_data.created_timestamp)}</Form.Label>
						</div>
					</div>

					<div className="mt-2">
						<Form.Label>Card Used: </Form.Label>
						<Form.Label><strong className="font-sub-title-larger ms-1">{props.selectedReportModal.printed_serial}</strong></Form.Label>
					</div>
					<div>
						<Form.Label>Description: </Form.Label>
						<Form.Label><strong className="font-sub-title-larger ms-1">{props.selectedReportModal.trans_data.type}</strong></Form.Label>
					</div>
					<div>
						<Form.Label>Transaction Status: </Form.Label>
						<Form.Label><strong className="font-sub-title-larger ms-1">{props.selectedReportModal.trans_data.status == "Claimed" ? "Success" : "Failed"}</strong></Form.Label>
					</div>
					<div>
						<Form.Label>Ref Number: </Form.Label>
						<Form.Label><strong className="font-sub-title-larger ms-1">{props.selectedReportModal.trans_data.trans_id}</strong></Form.Label>
					</div>

					{

						

						// BA & Marketing requirement: Cancelled card are not able to dispute
						props.selectedReportModal.card_is_cancelled == false ?
							// BA & Marketing requirement: Dispute button appear for deduction only
							props.selectedReportModal.trans_data.amount.includes("-") ?

								loading ?
									<div className="text-center mt-3 mb-3">
										<button className="btn btn-primary primary-button" type="button" disabled>Loading...</button>
									</div>
									:
									// props.selectedReportModal.userData.email.includes("handalindah.com.my") || 
									// props.selectedReportModal.userData.manjalink_id == "17751" || // marketing acc
									// props.selectedReportModal.userData.manjalink_id == "3" || // testing team acc
									// props.selectedReportModal.userData.manjalink_id == "30191" || // testing team acc
									// props.selectedReportModal.userData.manjalink_id == "111418" ? // testing team acc

									// BA & Marketing requirement: Dispute can only submit maximum 2 times on same transaction
									overchargeDisputable(disputeStatus?.Status, disputeStatus?.Number_Of_Dispute) == true ?
										// Dispute rejected, but < 2 times, display status and dispute button
										disputeStatus?.Status == "REJECTED" ?
											<>
												<DisputeStatus
													disputeStatus={disputeStatus?.Status}
													disputeRejectReason={disputeStatus?.Remark}
													disputeDate={changeDateTimeDDMMYYYYFormatToDDMMMYYYY(disputeStatus?.Created_Timestamp)}
													disputeAmount={disputeStatus?.Refund_Amount}
												/>
												<div className="text-center mt-3 mb-3">
													<button className="btn btn-primary primary-button" type="button" onClick={goToPage2}>Dispute</button>
												</div>
											</>
											:
											// No dispute record found, show dispute button
											<div className="text-center mt-3 mb-3">
												<button className="btn btn-primary primary-button" type="button" onClick={goToPage2}>Dispute</button>
											</div>
										:
										// Not disputable (In progress / rejected >= 2 times / refund completed), display dispute status
										<DisputeStatus
											disputeStatus={disputeStatus?.Status}
											disputeDate={changeDateTimeDDMMYYYYFormatToDDMMMYYYY(disputeStatus?.Created_Timestamp)}
											disputeRejectReason={disputeStatus?.Status == 'REJECTED' && disputeStatus?.Remark}
											disputeAmount={disputeStatus?.Refund_Amount}
										/>
								// : ""
								: ""
							: ""
					}
				</Form>
			</div>
		)
	}
	else {
		return (
			<div className="pop-out-modal dispute-form">
				{/* <h5 className="pb-2">Remove card will delete your transaction history permanently. <br/>Are you sure you want to remove card ID: {props.printed_serial}?</h5> */}
				<Form>
					<Row>
						<Col md={6}>
							<Form.Label>Name:</Form.Label>
							<Form.Control type="text" placeholder="NAME" defaultValue={props.selectedReportModal.userData.full_name ?? null} ref={nameRef} ></Form.Control>
						</Col>
						<Col md={6}>
							<Form.Label>Contact No:</Form.Label>
							<Form.Control type="text" placeholder="CONTACT NO" defaultValue={props.selectedReportModal.userData.mobile ?? null} ref={contactRef} ></Form.Control>
						</Col>
					</Row>
					<Form.Label>Email:</Form.Label>
					<Form.Control type="text" placeholder="EMAIL" defaultValue={props.selectedReportModal.userData.email ?? null} ref={emailRef} ></Form.Control>
					<Form.Control type="text" placeholder="SUBJECT" ref={subjectRef} ></Form.Control>

					<Row>
						<Col md={6}>
							<Form.Control type="text" placeholder="Boarding Location" ref={boardingLocationRef} ></Form.Control>
						</Col>
						<Col md={6}>
							<Form.Control type="text" placeholder="Destination" ref={destinationRef} ></Form.Control>
						</Col>
					</Row>

					<Form.Control type="text" as="textarea" rows="7" placeholder="MESSAGE" ref={messageRef} ></Form.Control>
					<Form.Label>Attachment:</Form.Label>
					<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" /></svg>
					<input className="attachment" type="file" accept="image/*" id="uploadDisputeImage" onChange={saveImage} />

					<label className="form-label label-info">Please be advised that the process is anticipated to require approximately 14 working days. We appreciate your patience in this matter.</label>

					{
						errorMessage && errorMessage.blankError
							?
							<label className="form-label label-danger">{errorMessage.blankError}</label>
							:
							errorMessage && errorMessage.emailError
								?
								<label className="form-label label-danger">{errorMessage.emailError}</label>
								:
								errorMessage && errorMessage.fileError
									?
									<label className="form-label label-danger">{errorMessage.fileError}</label>
									: ""
					}
					<div className="text-center">
						<button className="btn btn-primary button-cancel" type="button" onClick={() => props.onHide(false)}>Cancel</button>
						<button className="btn btn-primary primary-button" type="button" onClick={disputeApiCall} disabled={buttonDisabled}>Submit</button>
					</div>
				</Form>
			</div>
		)
	}
}
export default DisputeForm;