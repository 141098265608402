export function apiCall(method='GET', SERVER_URL, api_url, requestOptions) {
  var APIresult = null;
  const request = {
          // mode: 'no-cors',
          method: method,
          headers: { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
      }
  if(requestOptions != null) {
      Object.assign(request, requestOptions)
  }
  // // console.log(request)
  if(method === 'GET') {
      APIresult = callApiGetMethod(SERVER_URL, api_url, request)
  }
  else {
      APIresult = callApiPostMethod(SERVER_URL, api_url, request)
  }
  return APIresult;
}

export function callApiGetMethod(SERVER_URL, api_url, requestOptions) {
  const getData = async () => {
      try {
          const response = await fetch(
              `${SERVER_URL}${api_url}`, requestOptions
          );
          if(!response.ok) {
              throw new Error(
                  `Error ${response.status}`
              );
          }
          let api_result = await response.json();
          return api_result;
      }
      catch(err) {
          // // console.log(err.message)
          return null;
      }
  }
  return getData()
}

export function callApiPostMethod(SERVER_URL, api_url, requestOptions) {
    // console.log(requestOptions)
  const getPOSTData = async () => {
    const result = {};
      try {
          const response = await fetch(
              `${SERVER_URL}${api_url}`, requestOptions
          );
          // console.log(response)
          if(!response.ok) {
              response.error = await response.text();
              const error = {
                message: response.error,
                status: response.status,
                ok: response.ok
              }
              throw new Error(
                JSON.stringify(error)
              );
          }
          else {
            result.status = response.status;
            result.ok = response.ok;
            if(response.status == '200' || response.status == '201') {
                result.api_result = await response.text();
                // console.log(result.api_result)
                if(result.api_result != "" && result.api_result.includes(":")) {
                    result.api_result = JSON.parse(result.api_result);
                }
            }
        }
        return result;
      }
      catch(err) {
          // console.log((err?.message))
          result.error = JSON.parse(err?.message);
          return (result);
      }
  }
  return getPOSTData()
}