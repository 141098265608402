import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { apiCall } from "../../helpers/ApiHelper";
import { changeDateFormatToYYYYMMDD } from "../../helpers/DateHelper";
import { getPrimaryEmail, getDiversifyKey, clearRegisterStatus, setUserName } from "../../helpers/StorageHelper";
import { checkIsFieldBlank } from "../../helpers/ValidationHelper";
import LoadingScreen from "../LoadingScreen";
import TitleWithBackButton from "../TitleWithBackButton";
import './form.css';

function EditProfile() {
    const {state} = useLocation();
    // console.log(state);
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const [countryCode, setCountryCode] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [input, setInput] = useState({
        manjalink_id: state?.userData?.manjalink_id,
        full_name: state?.userData?.full_name,
        mobile: state?.userData?.mobile,
        gender: state?.userData?.gender,
        dob: state?.userData?.dob,
        country_code: state?.userData?.country_code != '' ? state?.userData?.country_code : 'MY'
    })
    const [submitButton, setSubmitButton] = useState(true)

    const setField = (field, value) => {
        setInput({
            ...input,
            [field]: value
        })
        if(submitButton == true) {
            setSubmitButton(false)
        }
    }

    // console.log(input)

    useEffect(()=>{
        apiCall('GET', SERVER_URL, 'ManjaProfile/SelectCountryCode')
        .then(response=>{
            // console.log(response)
            setCountryCode(response)
            setLoading(false)
        })
        if(!state) {
            apiCall('GET', SERVER_URL, `ManjaProfile/SelectManjaProfileByEmail/${getPrimaryEmail()}/${getDiversifyKey()}`)
            .then(result => {
                if(result) {
                    setInput({
                        manjalink_id: result[0]?.manjalink_id,
                        full_name: result[0]?.full_name,
                        mobile: result[0]?.mobile,
                        gender: result[0]?.gender,
                        dob: result[0]?.dob,
                        country_code: result[0]?.country_code != '' ? result[0]?.country_code : 'MY'
                    });
                    setLoading2(false);
                }
                else {
                    alert('Unable to retrieve user data');
                    setLoading2(false);
                }
            })
        }
    },[])

    const updateProfile = () => {
        if(state?.userData?.ic_number != "" && state?.userData?.ic_number != null) {
            const isFieldBlank = checkIsFieldBlank([input.manjalink_id, input.full_name, input.mobile]);
            if(isFieldBlank == false) {
                const requestOptions = {
                    body: JSON.stringify({
                        ManjaLinkID: input.manjalink_id,
                        FullName: input.full_name,
                        Mobile: input.mobile,
                        Country: input.country_code,
                        LoginName: input.full_name,
                        Gender: input.gender,
                        DOB: input.dob,
                        CountryCode: input.country_code
                    })
                }
                apiCall('POST', SERVER_URL, 'ManjaProfile/UpdateWebMemberInfo', requestOptions)
                .then(response=>{
                    // console.log(response)
                    if(response.ok == true) {
                        clearRegisterStatus();
                        setUserName(input.full_name)
                        alert('Update Successfully')
                        window.location.href = '/myAccount';
                    }
                    else {
                        alert('Error while editing')
                    }
                })
            }
            else {
                alert("Please fill in all required field")
            }
        }
        else {
            const isFieldBlank = checkIsFieldBlank([input.manjalink_id, input.full_name, input.mobile, input.ic]);
            if(isFieldBlank == false) {
                const requestOptions = {
                    body: JSON.stringify({
                        ManjaLinkID: input.manjalink_id,
                        FullName: input.full_name,
                        Mobile: input.mobile,
                        Country: input.country_code,
                        LoginName: input.full_name,
                        Gender: input.gender,
                        DOB: input.dob,
                        CountryCode: input.country_code,
                        IC: input.ic
                    })
                }
                apiCall('POST', SERVER_URL, 'ManjaProfile/UpdateWebMemberInfo', requestOptions)
                .then(response=>{
                    // console.log(response)
                    if(response.ok == true) {
                        clearRegisterStatus();
                        setUserName(input.full_name)
                        alert('Update Successfully')
                        window.location.href = '/myAccount';
                    }
                    else {
                        alert('Error while editing')
                    }
                })
            }
            else {
                alert("Please fill in all required field")
            }
        }
    }

    // console.log(state)
    if(loading) return (<LoadingScreen />)
    else if(loading2 && state == null) return (<LoadingScreen />)
    return(
        <Container>
            <TitleWithBackButton 
            backto={state && state?.userData?.ic_number ? '/myAccount' : null}
            title='Edit Profile'
            />
            <div className="form-container mw-800 m-auto">
                <Form>
                    <Form.Label className="font-sub-title-large">Full Name ( as per IC/passport )<span className='red'> *</span></Form.Label>
                    <Form.Control className="font-sub-title-large" type='text' onChange={e=>setField('full_name', e.target.value)} defaultValue={state?.userData?.full_name} required></Form.Control>
                    <Form.Label className="font-sub-title-large">Email<span className='red'> *</span></Form.Label>
                    <Form.Control className="font-sub-title-large" type='text' defaultValue={state?.userData?.email ?? getPrimaryEmail() } disabled></Form.Control>
                    <Row>
                        <Col md={6}>
                            <Form.Label className="font-sub-title-large">Gender</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Check onChange={e=>setField('gender', 'M')} className="me-5 font-sub-title-large" inline label="Male" name="gender" type='radio' id={`inline-radio-1`} value="M" defaultChecked={state?.userData?.gender == 'M' ? true : false}  />
                                    <Form.Check onChange={e=>setField('gender', 'F')} className="font-sub-title-large" inline label="Female" name="gender" type='radio' id={`inline-radio-2`} value="F" defaultChecked={state?.userData?.gender == 'F' ? true : false} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} className="font-sub-title-large">
                            <Form.Label>Date of Birth</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control className="font-sub-title-large" type='date' defaultValue={changeDateFormatToYYYYMMDD(state?.userData?.dob)} onChange={e=>setField('dob', e.target.value)}></Form.Control>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Form.Label className="font-sub-title-large">IC / Passport No.<span className='red'> *</span></Form.Label>
                    <Form.Control className="font-sub-title-large" type='text' defaultValue={state?.userData?.ic_number} onChange={e=>setField('ic', e.target.value)} disabled={state?.userData?.ic_number ? true : false}></Form.Control>
                    
                    <Form.Label className="font-sub-title-large">Contact Number<span className='red'> *</span></Form.Label>
                    <Form.Control className="font-sub-title-large" type='tel' defaultValue={state?.userData?.mobile} onChange={e=>setField('mobile', e.target.value)}></Form.Control>
                    
                    <Form.Label className="font-sub-title-large">Nationality</Form.Label>
                    <Form.Select className="font-sub-title-large" defaultValue={state?.userData?.country_code != '' && state?.userData?.country_code != null ? state?.userData?.country_code : 'MY'} onChange={e=>setField('country_code', e.target.value)}>
                        {
                            countryCode && countryCode.map(({country_code, country_name},index) => (
                                <option value={country_code}>{country_name}</option>
                            ))
                        }
                    </Form.Select>
                </Form>
            </div>
            <div className="m-auto mt-2 mw-800 submit-button-position">
                <button className="btn btn-primary save-button" type="button" disabled={submitButton} onClick={()=>updateProfile()}>Save Changes</button>
            </div>
        </Container>
    )
}
export default EditProfile;