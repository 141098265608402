//check whether the string contains uppercase
export function containsUppercase(str) {
  return /[A-Z]/.test(str);
}

//check whether the string contains lowercase
export function containsLowercase(str) {
  return /[a-z]/.test(str);
}

//check whether the string contains number
export function containsNumber(str) {
  return /[0-9]/.test(str);
}

//check whether the string contains either one symbol '!','?','@','$'
export function containsSymbol(str) {
  if(/!/.test(str) === true || /\?/.test(str) === true || /\@/.test(str) === true || /\$/.test(str) === true) {
      return true
  }
  else return false;
}

//check whether the email is in correct format
export function emailFormat(str) {
  if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str)) {
      return true
  }
  else return false;
}

// check whether the field is blank
// return true = has blank field
// return false = no blank field
export function checkIsFieldBlank(arrayOfFields) {
  let fieldIsBlank = false;
  if(arrayOfFields != null && arrayOfFields.length != 0) {
    arrayOfFields.forEach(element => {
      if(element == null || element == "" || element == " ") {
        fieldIsBlank = true
      }
    });
    return fieldIsBlank
  }
  else {
    return true
  }
}

export function checkIsManjaPay(printed_serial) {
  if(printed_serial) {
    return printed_serial.startsWith("EW");
  }
  return false;
}