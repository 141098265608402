export function hideSensitiveDataMiddle(str) {
    if(str.includes("@")) {
        let strArr = str.split("@");
        str = strArr[0];
        let email = strArr[1];
        return str.replace(/\b(\w)(\w+)?(\w)\b/g, function(match, first, middle, last) {
            return first + (middle ? middle.replace(/./g, '*') : '') + last;
        }) + "@"+email;
    }
    else {
        return str.replace(/\b(\w)(\w+)?(\w)\b/g, function(match, first, middle, last) {
            return first + (middle ? middle.replace(/./g, '*') : '') + last;
        });
    }
}

export function hideSensitiveDataFront(str) {
    if(str && str.length > 2) {
        return "*".repeat(str.length - 2) + str.slice(-2);
    }
    else {
        return str;
    }
}

export function overchargeDisputable(overchargeStatus, number_of_dispute) {
    // NEW, APPROVED, OPEN
    // NOT EXISTS, REJECTED, APPROVED, IN PROGRESS, PENDING REFUND

	console.log(`number_of_dispute = ${number_of_dispute}`);
	console.log(number_of_dispute);

    if(overchargeStatus && number_of_dispute) {
        if(number_of_dispute >= 2) {
            return false;
        }
        const status = overchargeStatus.toUpperCase();
        switch(status) {
            case 'NOT EXISTS': return true;
            case 'REJECTED': return true;
            case 'APPROVED': return false;
            case 'PENDING REFUND': return false;
            case 'IN PROGRESS': return false;
            default: return true;
        }
    }
    return true;
}

export function overchargeStatusMessage(overchargeStatus) {
    // NEW, APPROVED, OPEN
    // NOT EXISTS, REJECTED, APPROVED, IN PROGRESS
    if(overchargeStatus) {
        const status = overchargeStatus.toUpperCase();
        switch(status) {
            case 'IN PROGRESS': return "Current Dispute still under investigation";
            case 'REJECTED': return "Your dispute transaction has been rejected";
            case 'APPROVED': return "Your dispute transaction has been approved";
            case 'PENDING REFUND': return "Your refund will be transfer to your account soon";
            default: return "Current Dispute still under investigation";
        }
    }
    return true;
}

export function getFileType(fileTypeString) {
    if(fileTypeString.includes("jpeg")) {
        return ".jpeg";
    }
    else if(fileTypeString.includes("png")) {
        return ".png";
    }
    else if(fileTypeString.includes("jpg")) {
        return ".jpg";
    }
    else {
        return ".jpg";
    }
}

export function capitalizeWords(inputString) {
    // Check if the inputString is empty or undefined
    if (!inputString) {
      return inputString;
    }
  
    // Split the inputString into words
    const words = inputString.split(' ');
  
    // Capitalize the first character of each word and convert the rest to lowercase
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  
    // Join the capitalized words back into a single string
    return capitalizedWords.join(' ');
}
  