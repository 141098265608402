import React, { useEffect, useState } from "react";
import ActivationResult from "../components/ActivationResult";
import LoadingScreen from "../components/LoadingScreen";
import { apiCall } from "../helpers/ApiHelper";

function UserActivationPage() {
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const searchParams = new URLSearchParams(document.location.search);
    const verification_code = searchParams.get("key");
    const [loading, setLoading] = useState(true);
    const [activationResult, setActivationResult] = useState(null);
    useEffect(()=>{
        if(verification_code != null) {
            //call api 
            // console.log(verification_code)
            const requestOptions = {
                body: JSON.stringify({
                    "VerificationCode": verification_code
                })
            }
            apiCall('POST', SERVER_URL, 'ManjaProfile/UpdateEmailVerification', requestOptions)
            .then(response=>{
                if(response) {
                    // console.log(response)
                    if(response.ok) {
                        setActivationResult(true)
                    }
                    else {
                        setActivationResult(false)
                    }
                    setLoading(false);
                }
            })
        }
        else {
            setLoading(false);
        }
    },[])
    if(loading) return (<LoadingScreen />)
    if(activationResult && activationResult == true) {
        return (
            <ActivationResult result='success' />
        )
    }
    else {
        return (
            <ActivationResult result='failed' />
        )
    }
}
export default UserActivationPage;