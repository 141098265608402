import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/navbar/Header';
import Footer from './components/navbar/Footer';
import LoginPage from './pages/LoginPage';
import EditProfile from './components/forms/EditProfile';
import ChangePassword from './components/forms/ChangePassword';
import ManageSubscription from './components/forms/ManageSubscription';
import MyAccountPage from './pages/MyAccountPage';
import TransactionHistoryPage from './pages/TransactionHistoryPage';
import TopUpPage from './pages/TopUpPage';
import TopUpPaymentAckPage from './pages/TopUpPaymentAckPage';
import LostOrCancelCardPage from './pages/LostOrCancelCardPage';
import RegistrationPage from './pages/RegistrationPage';
import PendingVerificationPage from './pages/PendingVerificationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ScrollToTop from './helpers/ScrollToTop';
import { getLoginStatus, getUserName, getPrimaryEmail, getDiversifyKey } from './helpers/StorageHelper';
import UserActivationPage from './pages/UserActivationPage';
import TagManager from 'react-gtm-module';
import StickyBannerTop from './components/navbar/StickyBannerTop';

function App() {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname
      }
    });
  }, []);

  const login = getLoginStatus();
  const userName = getUserName();
  const email = getPrimaryEmail();
  const user_key = getDiversifyKey();
  
  // console.log(login) 
  // console.log(email) 
  // console.log(user_key) 
  // console.log(sessionStorage)
  // console.log(!window.location.pathname.includes('/login'))
  if(!window.location.pathname.includes('/login') 
  && !login && !window.location.pathname.includes('/register') 
  && !window.location.pathname.includes('/forgotPassword') 
  && !window.location.pathname.includes('/userActivation')
  && !window.location.pathname.includes('/pendingVerification')
  && !window.location.pathname.includes('/paymentAck') ) {
    window.location.href = '/login';
  }
  
  return (
    <Router>
      <StickyBannerTop />
      <Header login={login} userName={userName} />
        <ScrollToTop>
          <Routes>
              <Route path='/' element={<LoginPage />} />
              <Route path='/login' element={<LoginPage />} />
              <Route path='/pendingVerification' element={<PendingVerificationPage />} />
              <Route path='/register' element={<RegistrationPage />} />
              <Route path='/forgotPassword' element={<ForgotPasswordPage />} />
              <Route path='/userActivation' element={<UserActivationPage />} />
              <Route path='/paymentAck' element={<TopUpPaymentAckPage />} />
              
              {
                login ?
                <>
                <Route path='/myAccount' element={<MyAccountPage login={login} userName={userName} email={email} user_key={user_key} />} />
                <Route path='/editProfile' element={<EditProfile />} />
                <Route path='/changePassword' element={<ChangePassword />} />
                <Route path='/manageSubscription' element={<ManageSubscription />} />
                <Route path='/transactionHistory' element={<TransactionHistoryPage />} />
                <Route path='/topUp' element={<TopUpPage />} />
                <Route path='/cancelCard' element={<LostOrCancelCardPage />} />
                </>
                : ""
              }
          </Routes>
        </ScrollToTop>
      <Footer />
    </Router>
  );
}

export default App;
