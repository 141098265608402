import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { changeDateFormatToDDMMMYYYY, checkIsTerminatedDate } from "../helpers/DateHelper";
import "./SingleCardInfo.css";

function SingleCardInfo(props) {
    const MANJA_IMAGE_URL = process.env.REACT_APP_MANJA_IMAGE_URL;
    // console.log(props)
    // cu_id
    // design_images
    // current_balance
    // card_exp_date

    return (
        <div className="form-container mw-800 m-auto mt-3 mb-4">
          <div>
            <Row className="mobile-align-center">
                <Col md={4} className="text-center align-self-center">
                    {
                        props?.design_images != null ?
                        <img src={`${MANJA_IMAGE_URL}${props?.design_images}`} className="card-img-adjustment" />
                        :
                        <img src={`assets/img/causewaylink_logo.png`} className="card-img-adjustment" />

                    }
                </Col>
                <Col md={3} className="align-self-end">
                    {
                        props?.loyaltyPoints != null ? 
                            <>
                                <Form.Label className="d-block mb-0 card-label">Balance</Form.Label>
                                <h4 className="w-100 fw-bold mb-0">{props.loyaltyPoints} PTs</h4>
                            </>
                        :
                        <>
                        <Form.Label className="d-block mb-0 card-label">Available Balance</Form.Label>
                        <h4 className="w-100 fw-bold mb-0">{props?.current_balance != null && props?.current_balance != "" ? props.current_balance : 0} TCr</h4>
                        
                        {
                            props?.top_up_amt != null ?
                            <>
                            <Form.Label className="d-block mb-0 card-label">Top Up Amount</Form.Label>
                            <h4 className="w-100 fw-bold mb-0">{props?.top_up_amt != null && props?.top_up_amt != "" ? props.top_up_amt : 0} TCr</h4>
                            </>
                            :
                            props?.title != "ManjaPay" ?
                            <>
                            <Form.Label className="d-block mb-0 card-label">Pending Balance</Form.Label>
                            <h4 className="w-100 fw-bold mb-0">{props?.rebate_amt != null && props?.rebate_amt != "" ? props.rebate_amt : 0} TCr</h4>
                            </>
                            : ""
                        }
                        </>
                    }
                </Col>
                <Col className="d-flex flex-column justify-content-end">
                    {
                        props?.printed_serial != null ? 
                        <>
                        <Form.Label className="d-block mb-0 card-label">ID: {props?.printed_serial} </Form.Label>
                        </>
                        : ""
                    }
                    {
                        props?.card_exp_date != null ? 
                        <>
                        <Form.Label className="d-block mb-0 card-label mt-0">Expiry Date: {" "}
                        {
                            props?.card_exp_date.includes("T") 
                            ? !checkIsTerminatedDate(changeDateFormatToDDMMMYYYY(props?.card_exp_date.split("T")[0])) ? "as of " + changeDateFormatToDDMMMYYYY(props?.card_exp_date.split("T")[0]) : "-"
                            : !checkIsTerminatedDate(changeDateFormatToDDMMMYYYY(props?.card_exp_date.split(" ")[0])) ? "as of " + changeDateFormatToDDMMMYYYY(props?.card_exp_date.split(" ")[0]) : "-"
                        }
                        </Form.Label>
                        </>
                        : props?.currentDate != null ? 
                        <Form.Label className="d-block mb-0 card-label mt-0">As of {props?.currentDate}</Form.Label>
                        : ""
                    }
                </Col>
            </Row>
          </div>
        </div>
    )
}
export default SingleCardInfo;