import React, { useState } from 'react';
import "./StickyBannerTop.css";

const StickyBannerTop = () => {
  const [isBannerVisible, setBannerVisible] = useState(true);

  const handleCloseBanner = () => {
    setBannerVisible(false);
  };

  const redirectToLugo = () => {
    window.location.href = 'https://rem7j.app.goo.gl/3CBx';
  }

  return (
    <div className={`sticky-banner ${isBannerVisible ? 'visible' : ''}`}>
      <button className="close-button" onClick={handleCloseBanner}>
        x
      </button>
      <div className="logo"><img src="assets/img/LUGO-app-logo.png" alt="lugo-logo" /></div>
      <div className="description">LUGO App: Instant Top-Ups Made Simple!</div>
      <button className="install-button" onClick={redirectToLugo}>OPEN</button>
    </div>
  );
};

export default StickyBannerTop;
