export function ArrayIntoChunk(array, chunk_size) {
    let result = null;

    result = array.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/chunk_size)

        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])
      
    return result;
}