import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { apiCall } from "../../helpers/ApiHelper";
import { checkIsFieldBlank, emailFormat } from "../../helpers/ValidationHelper";
import HeaderBackgroundImage from "../HeaderBackgroundImage";
import './form.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

function ForgotPassword(props) {
    // console.log(window.location.href)
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const location = useLocation();
    const emailRef = useRef(null);
    const PasswordRef = useRef(null);
    const RePasswordRef = useRef(null);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const [resetPage, setResetPage] = useState(false);
    const [fieldBlankError, setFieldBlankError] = useState(false);
    const [rePasswordError, setRePasswordError] = useState(false);
    const [commonError, setCommonError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailNotFoundError, setEmailNotFoundError] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [success, setSuccess] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showReNewPassword, setShowReNewPassword] = useState(false);

    // console.log(id)
    useEffect(()=>{
        sessionStorage.clear();
        if(id != null) {
            const requestOptions = {
                body: JSON.stringify({
                    VerificationCode: id
                })
            }
            apiCall('POST', SERVER_URL, 'ManjaProfile/UpdateResetPasswordRequest', requestOptions)
            .then(response=>{
                // console.log(response)
                if(response) {
                    if(response.status == '200') {
                        setResetPage(true)
                    }
                }
            })
        }
      },[])

    const verifyEmail = () => {
        setEmailSuccess(false)
        setCommonError(false)
        setRePasswordError(false)
        setSuccess(false)
        setEmailError(false)
        setEmailNotFoundError(false)
        setFieldBlankError(false)
        const isFieldBlank = checkIsFieldBlank([emailRef.current.value]);
        if(isFieldBlank === false && emailFormat(emailRef.current.value)) {
            const requestOptions = {
                body: JSON.stringify({
                    Email: emailRef.current.value
                })
            }
            apiCall('POST', SERVER_URL, 'ManjaProfile/ResetPassword', requestOptions)
            .then(response=>{
                // console.log(response)
                if(response) {
                    if(response.status == '200') {
                        // send email successful
                        setEmailSuccess(true)
                    }
                    else {
                        setEmailNotFoundError(true)
                    }
                }
                else {
                    setCommonError(true)
                }
            })
        }
        else {
            setEmailError(true)
        }
    }

    const resetPassword = () => {
        setRePasswordError(false)
        setEmailSuccess(false)
        setCommonError(false)
        setSuccess(false)
        setEmailError(false)
        setEmailNotFoundError(false)
        setFieldBlankError(false)
        const isFieldBlank = checkIsFieldBlank([PasswordRef.current.value, RePasswordRef.current.value]);
        if(isFieldBlank === false) {
            if(PasswordRef.current.value === RePasswordRef.current.value) {
                const requestOptions = {
                    body: JSON.stringify({
                        NewPassword: PasswordRef.current.value,
                        ReNewPassword: RePasswordRef.current.value,
                        VerificationCode: id
                    })
                }
                apiCall('POST', SERVER_URL, 'ManjaProfile/ResetNewPassword', requestOptions)
                .then(response=>{
                    // console.log(response)
                    if(response) {
                        if(response.status == '204') {
                            // update password successful
                            setSuccess(true)
                        }
                        else {
                            setCommonError(true)
                        }
                    }
                    else {
                        setCommonError(true)
                    }
                })
            }
            else {
                setRePasswordError(true)
            }
        }
        else {
            setFieldBlankError(true)
        }
    }
    // console.log(resetPage)

    return (
        <div className="form">
            <HeaderBackgroundImage />
            <Form>
            <div className="loginRegister">
                <div className="text-center bg-white">
                    <h4>Reset Your Password</h4>            
                </div>
                {
                    !resetPage ?
                    <div className="loginRegister-form-container">
                        <div>
                            <Row>
                                <Col>
                                    <Form.Label>Email</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type='email' ref={emailRef}></Form.Control>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    : 
                    <div className="loginRegister-form-container">
                        <div>
                            <Row>
                                <Col>
                                    <Form.Label>New Password</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="position-relative">
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    ref={PasswordRef}
                                    className="password-input"
                                />
                                <div
                                    className="password-toggle-icon"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Re-type New Password</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="position-relative">
                                    <Form.Control
                                        type={showReNewPassword  ? 'text' : 'password'}
                                        ref={RePasswordRef}
                                        className="password-input"
                                    />
                                    <div
                                        className="password-toggle-icon"
                                        onClick={() => setShowReNewPassword(!showReNewPassword)}
                                    >
                                        {showReNewPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                {
                    fieldBlankError ? <label className="form-label label-danger ps-4 pe-3">Please fill in all required field.</label>
                    : commonError ? <label className="form-label label-danger ps-4 pe-3">System unable to process your request, please try again later.</label>
                    : rePasswordError ? <label className="form-label label-danger ps-4 pe-3">Your new password does not matches with the re-enter new password you provided.</label>
                    : emailNotFoundError ? <label className="form-label label-danger ps-4 pe-3">{emailRef.current.value} is not a registered member. Click <Link to="/register">here</Link> to register as new member</label>
                    : emailError ? <label className="form-label label-danger ps-4 pe-3">Email address is invalid format</label>
                    : emailSuccess ? <label className="form-label label-success ps-4 pe-3">Your ManjaLink password has been reset, please check your email for more details. Click <Link to="/login">here</Link> to login</label>
                    : success ? <label className="form-label label-success ps-4 pe-3">Password successfully updated! Click <Link to="/login">here</Link> to login</label>
                    : ""
                }
            </div>
            <div className="text-center mb-2">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={resetPage === false ? ()=>verifyEmail() : ()=>resetPassword()}
                >
                    Submit
                </button>
            </div>
            </Form>
        </div>
    );
}
export default ForgotPassword;
