import React from 'react';
import { apiCall } from '../helpers/ApiHelper';
import { getPrimaryEmail } from '../helpers/StorageHelper';
import './PendingVerificationPage.css'; // Import the CSS file

const PendingVerificationPage = () => {
    const feedbackEmail = process.env.REACT_APP_SUPPORT_EMAIL;
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const _email = getPrimaryEmail();
    const email = _email.replace(/"/g, '');
    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    const resendVerificationEmail = () => {
        const requestOptions = {
            body: JSON.stringify({ 
                Email: email
            })
        }
        apiCall('POST', SERVER_URL, `ManjaProfile/ResendVerificationEmail`, requestOptions)
        .then(response => {
            // console.log(response);
            if(response) {
                if(response.status == '204' || response.status == '208') {
                    alert(`Email successfully sent to ${email}`);
                    setButtonDisabled(true);
                    window.location.href = '/login';
                }
                else if(response.status == '206') {
                    alert(`${email} has been registered, please proceed to login`);
                    window.location.href = '/login';
                }
                else if(response.status == '404') {
                    alert(`${email} is not a registered member, please proceed to make registration`);
                    window.location.href = '/register';
                }
                else {
                    alert("Failed to resend verification email, please try again later");
                }
            }
        })
    }
    if(email)
    return (
        <div className="verify-email-container">
            <div className="title">Verify Your Email Address</div>

            <div className="subtitle">
                To continue using Manjalink, please verify your email address
            </div>

            <div className="label">{email}</div>

            <button className="verification-button" onClick={resendVerificationEmail} disabled={buttonDisabled}>
                {buttonDisabled ? "EMAIL SENT" : "SEND VERIFICATION EMAIL"}
            </button>

            <div className="trouble-verifying">
                <a href={`mailto:${feedbackEmail}`} className="trouble-verifying-link">
                Trouble Verifying?
                </a>
            </div>
        </div>
    );
    else {
        window.location.href = '/';
    }
};

export default PendingVerificationPage;
