import React, { useEffect, useState } from "react";
import { Form, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { apiCall } from "../../helpers/ApiHelper";
import LoadingScreen from "../LoadingScreen";
import TitleWithBackButton from "../TitleWithBackButton";
import './form.css';

function ManageSubscription() {
    const {state} = useLocation();
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const [input, setInput] = useState({
        Newsletter: false,
        LatestPromotion: false,
        ProductUpdate: false,
        PointSummary: false,
        Unsubscribe: true
    })
    useEffect(()=>{
        if(state?.manjalink_id) {
            apiCall('GET', SERVER_URL, `ManjaProfile/SelectMemberPreference/${state?.manjalink_id}`)
            .then(response => {
                // console.log(response)
                setLoading(false);
                if(response != null && response.length > 0) {
                    let unsubsribe = true;
                    if(response[0].newsletter == "True" || response[0].latest_promotion == "True" || response[0].product_update == "True" || response[0].point_summary == "True") {
                        unsubsribe = false;
                    }
                    setInput({
                        Newsletter: response[0].newsletter == "True" ? true : false,
                        LatestPromotion: response[0].latest_promotion == "True" ? true : false,
                        ProductUpdate: response[0].product_update == "True" ? true : false,
                        PointSummary: response[0].point_summary == "True" ? true : false,
                        Unsubscribe: unsubsribe
                    })
                }
            })
        }
        else {
            setLoading(false)
        }
    },[])
    
    const [submitButton, setSubmitButton] = useState(true)
    const setField = (field, value) => {
        setInput({
            ...input,
            [field]: value
        })
        if(submitButton == true) {
            setSubmitButton(false)
        }
        const check1 = document.getElementById("check1")
        const check2 = document.getElementById("check2")
        const check3 = document.getElementById("check3")
        const check4 = document.getElementById("check4")
        if(field == "Unsubscribe") {
            if (value == true) {
                check1.checked = false
                check2.checked = false
                check3.checked = false
                check4.checked = false
                setInput({
                    Newsletter: false,
                    LatestPromotion: false,
                    ProductUpdate: false,
                    PointSummary: false,
                    Unsubscribe: true
                })
            }
            else {
                check1.checked = true
                check2.checked = true
                check3.checked = true
                check4.checked = true
                setInput({
                    Newsletter: true,
                    LatestPromotion: true,
                    ProductUpdate: true,
                    PointSummary: true,
                    Unsubscribe: false
                })
            }
        }
        else {
            // const check1 = document.getElementById("check1")
            // const check2 = document.getElementById("check2")
            // const check3 = document.getElementById("check3")
            // const check4 = document.getElementById("check4")
            if (!check1.checked && !check2.checked && !check3.checked && !check4.checked) {
                const check5 = document.getElementById("check5")
                check5.checked = true
            }
            if (value == true) {
                const check5 = document.getElementById("check5")
                check5.checked = false
            }
        }
    }

    const updateSubscription = () => {
        setSuccess(false)
        setFailed(false)
        if(input.Newsletter != null 
            && input.LatestPromotion != null 
            && input.ProductUpdate != null 
            && input.PointSummary != null ) {
                const requestOptions = {
                    body: JSON.stringify({
                        ManjalinkID: state?.manjalink_id,
                        Newsletter: input.Newsletter ? 1 : 0,
                        LatestPromotion: input.LatestPromotion ? 1 : 0,
                        ProductUpdate: input.ProductUpdate ? 1 : 0,
                        PointSummary: input.PointSummary ? 1 : 0
                    })
                }
                apiCall('POST', SERVER_URL, 'ManjaProfile/UpdateMemberPreference', requestOptions)
                .then(response => {
                    // console.log(response)
                    if(response.ok == true) {
                        setSuccess(true)
                    }
                    else {
                        setFailed(true)
                    }
                })
        }
    }

    // console.log(input)
    if(loading) return(<LoadingScreen />);
    return(
        <Container>
            <TitleWithBackButton 
            backto = '/myAccount'
            title = 'Manage Subscription'
            />
            <div>
                <h6 className="text-center mb-1 fw-bold font-sub-title">Select the news you prefer to receive from ManjaLink.</h6>
                <h6 className="text-center font-sub-title" >You can update your preference at any time and we will only send you email.</h6>
            </div>
            <div className="form-container mw-800 m-auto">
                <Form>
                    <div className="sub-form-container font-sub-title-large">
                        <Row>
                            <Col sm={9}>
                                <Form.Label className="mb-1 fw-bold">Newsletter</Form.Label>
                                <p className="small">
                                    <span>Receive monthly newsletter to get latest news from ManjaLink, Causeway Link, Causeway Link Holidays and LUGO.</span>
                                    <br />
                                </p>
                            </Col>
                            <Col sm={3} className="switch-align m-auto">
                                <Form.Check id="check1" className="big-switch" type='switch' onChange={e=>setField('Newsletter', e.target.checked)} defaultChecked={input.Newsletter}></Form.Check>
                            </Col>
                        </Row>
                    </div>
                    <div className="sub-form-container font-sub-title-large">
                        <Row>
                            <Col sm={9}>
                                <Form.Label className="mb-1 fw-bold">Latest Promotion</Form.Label>
                                <p className="small">
                                    <span>Receive latest promotion from ManjaLink, Causeway Link, Causeway Link Holidays and LUGO.</span>
                                    <br />
                                </p>
                            </Col>
                            <Col sm={3} className="switch-align m-auto">
                                <Form.Check id="check2" className="big-switch" type='switch' onChange={e=>setField('LatestPromotion', e.target.checked)} defaultChecked={input.LatestPromotion}></Form.Check>
                            </Col>
                        </Row>
                    </div>
                    <div className="sub-form-container font-sub-title-large">
                        <Row>
                            <Col sm={9}>
                                <Form.Label className="mb-1 fw-bold">Causeway Link Product Updates</Form.Label>
                                <p className="small">
                                    <span>Receive Latest product information from ManjaLink, Causeway Link, Causeway Link Holidays and LUGO.</span>
                                    <br />
                                </p>
                            </Col>
                            <Col sm={3} className="switch-align m-auto">
                                <Form.Check id="check3" className="big-switch" type='switch' onChange={e=>setField('ProductUpdate', e.target.checked)} defaultChecked={input.ProductUpdate}></Form.Check>
                            </Col>
                        </Row>
                    </div>
                    <div className="sub-form-container font-sub-title-large">
                        <Row>
                            <Col sm={9}>
                                <Form.Label className="mb-1 fw-bold">Causeway Link Express Loyalty Program</Form.Label>
                                <p className="small">
                                    <span>Receive Causeway Link Express Loyalty Program points summary and news.</span>
                                    <br />
                                </p>
                            </Col>
                            <Col sm={3} className="switch-align m-auto">
                                <Form.Check id="check4" className="big-switch" type='switch' onChange={e=>setField('PointSummary', e.target.checked)} defaultChecked={input.PointSummary}></Form.Check>
                            </Col>
                        </Row>
                    </div>
                    <div className="sub-form-container font-sub-title-large">
                        <Row>
                            <Col sm={9}>
                                <Form.Label className="mb-1 fw-bold">Unsubscribe from all marketing emails</Form.Label>
                                <p className="small">
                                    <span>This includes newsletter, latest promotion, Causeway Link product updates and Causeway Link Express loyalty program information. You will still receive non-marketing emails based on your settings.</span>
                                    <br />
                                </p>
                            </Col>
                            <Col sm={3} className="switch-align m-auto">
                                <Form.Check id="check5" className="big-switch" type='switch' onChange={e=>setField('Unsubscribe', e.target.checked)} defaultChecked={input.Unsubscribe}></Form.Check>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="mw-800 m-auto mt-2 submit-button-position">
            {
                failed ? <label className="form-label label-danger">Update failed.</label>
                : success ? <label className="form-label label-success">Successfully updated!</label>
                : ""
            }
                <button className="btn btn-primary save-button" type="button" disabled={submitButton} onClick={updateSubscription}>Save Changes</button>
            </div>
        </Container>
    )
}
export default ManageSubscription;