import React from "react";
import './forms/form.css';

function HeaderBackgroundImage() {
    return (
        <div className="height250">
            <img src="assets/img/login_banner.jpg" className="mt-65 height-width100" />
        </div>
    )
}
export default HeaderBackgroundImage;