import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { convertTimeStampDateToYYYYMMDD, changeDateTimeFormatToDDMMMYYYY } from "../helpers/DateHelper";
import './MyManjaLinkCard.css'

function MyManjaPay(props) {
	const topUp = () => {
		window.location.href = '/topUp'
	}
	// console.log(props.manjaPay)

	if (props.manjaPay != null) {
		return (
			<Container>
				{
					props.manjaPay && props.manjaPay.map(({ cu_id, printed_serial, design_images, current_balance, tu_timestamp, current_balance_timestamp, card_exp_date }, index) => (
						<Row>
							<Col md={6}>
								<div className="bw-0">
									<label className="form-label fw-bold">My ManjaPay</label>

									<div className="bg-warning p-3 mb-3">
										<p className="mt-1 mb-1 font-sub-title fw-bold">Important Note</p>
										<p className="font-sub-title">ManjaPay is only applicable for myBAS Johor Bahru, SBST Melaka, and UiTM Lendu services and is not applicable for cross-border bus services.</p>
									</div>


									<div className="card br-20">
										<Container>
											<Row className="mb-3 pt-3 row">
												<Col sm={12} md={5} lg={4}>
													<img src={`https://www.manjalink.com.my/images/design/small/${design_images}`} />
												</Col>
												<Col sm={12} md={7} lg={8}>
													<p className="mb-0">Balance</p>
													<h4 className="fw-bold">{current_balance ? current_balance : 0} TCR</h4>
													<p className="mb-2 font-sub-title">Last Update: {changeDateTimeFormatToDDMMMYYYY(current_balance_timestamp)}</p>
													<Row>
														<Col>
															<Link to="/topUp" state={{
																title: "ManjaPay",
																manjalink_id: props?.manjalink_id,
																cu_id: cu_id,
																printed_serial: printed_serial,
																design_images: design_images,
																current_balance: current_balance,
																userData: props?.userData
																// card_exp_date: card_exp_date
															}}>
																<button className="btn btn-primary topup-button" type="button" >
																	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="pe-0 me-1">
																		<path d="M326.7 403.7C304.7 411.6 280.8 416 256 416C231.2 416 207.3 411.6 185.3 403.7C184.1 403.6 184.7 403.5 184.5 403.4C154.4 392.4 127.6 374.6 105.9 352C70.04 314.6 48 263.9 48 208C48 93.12 141.1 0 256 0C370.9 0 464 93.12 464 208C464 263.9 441.1 314.6 406.1 352C405.1 353 404.1 354.1 403.1 355.1C381.7 376.4 355.7 393.2 326.7 403.7L326.7 403.7zM235.9 111.1V118C230.3 119.2 224.1 120.9 220 123.1C205.1 129.9 192.1 142.5 188.9 160.8C187.1 171 188.1 180.9 192.3 189.8C196.5 198.6 203 204.8 209.6 209.3C221.2 217.2 236.5 221.8 248.2 225.3L250.4 225.9C264.4 230.2 273.8 233.3 279.7 237.6C282.2 239.4 283.1 240.8 283.4 241.7C283.8 242.5 284.4 244.3 283.7 248.3C283.1 251.8 281.2 254.8 275.7 257.1C269.6 259.7 259.7 261 246.9 259C240.9 258 230.2 254.4 220.7 251.2C218.5 250.4 216.3 249.7 214.3 249C203.8 245.5 192.5 251.2 189 261.7C185.5 272.2 191.2 283.5 201.7 286.1C202.9 287.4 204.4 287.9 206.1 288.5C213.1 291.2 226.4 295.4 235.9 297.6V304C235.9 315.1 244.9 324.1 255.1 324.1C267.1 324.1 276.1 315.1 276.1 304V298.5C281.4 297.5 286.6 295.1 291.4 293.9C307.2 287.2 319.8 274.2 323.1 255.2C324.9 244.8 324.1 234.8 320.1 225.7C316.2 216.7 309.9 210.1 303.2 205.3C291.1 196.4 274.9 191.6 262.8 187.9L261.1 187.7C247.8 183.4 238.2 180.4 232.1 176.2C229.5 174.4 228.7 173.2 228.5 172.7C228.3 172.3 227.7 171.1 228.3 167.7C228.7 165.7 230.2 162.4 236.5 159.6C242.1 156.7 252.9 155.1 265.1 156.1C269.5 157.7 283 160.3 286.9 161.3C297.5 164.2 308.5 157.8 311.3 147.1C314.2 136.5 307.8 125.5 297.1 122.7C292.7 121.5 282.7 119.5 276.1 118.3V112C276.1 100.9 267.1 91.9 256 91.9C244.9 91.9 235.9 100.9 235.9 112V111.1zM48 352H63.98C83.43 377.9 108 399.7 136.2 416H64V448H448V416H375.8C403.1 399.7 428.6 377.9 448 352H464C490.5 352 512 373.5 512 400V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V400C0 373.5 21.49 352 48 352H48z"></path>
																	</svg>Top Up
																</button>
															</Link>
														</Col>
														<Col className="text-center d-flex justify-content-center align-items-center">
															<Link to="/transactionHistory" className="font-primary-success"
																state={{
																	title: "ManjaPay",
																	userData: props?.userData,
																	cu_id: cu_id,
																	// card_exp_date: card_exp_date,
																	printed_serial: printed_serial,
																	design_images: design_images,
																	current_balance: current_balance,
																	tu_timestamp: tu_timestamp
																}}>
																Transaction
																<i className="fas fa-chevron-right ms-1"></i>
															</Link>
														</Col>
													</Row>
												</Col>
											</Row>
										</Container>
									</div>
								</div>
							</Col>
						</Row>
					))
				}
			</Container>
		)
	}
}
export default MyManjaPay;