import React, { useEffect, useState } from "react";
import { Container, Row, Col, Navbar, NavbarBrand, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { apiCall } from "../../helpers/ApiHelper";
import { getLoginStatus, getRegisterStatus, setLoginStatus, setUserData } from "../../helpers/StorageHelper";
import './Navbar.css';

function Header(props) {
    const MANJALINK_DOMAIN = process.env.REACT_APP_MANJALINK_DOMAIN
    const SERVER_URL = process.env.REACT_APP_MANJA_WEBAPI_URL;
    const [currencyRate, setCurrencyRate] = useState(null)
    // console.log(props)

    useEffect(()=>{
        apiCall('GET', SERVER_URL, 'ManjaLink/SelectCurrentSGDCurrency')
        .then(result=>{
            // console.log(result)
            setCurrencyRate(result)
        })
    },[])
    const logout = () => {
        sessionStorage.clear();
    }

    return (
        <div className="header-nav">
            <div className="header-background">
                <Container>
                    <Row className="p-15">
                        <Col>
                            <p className="d-flex pt-2 mobile-font-size"><br />
                            <strong><span className="font-white nav-top-info">Current Exchange Rate $1 = RM{currencyRate?.currency_rate}</span></strong>
                            <em><span className="font-white nav-top-info">(Updated on {currencyRate?.start_date})</span></em>
                            <br /><br />
                            </p>
                        </Col>
                        {/* <Col sm={12} md={4} lg={3} className="d-sm-flex justify-content-md-end mobile-hidden">
                            <span className="text-start font-white pt-2 fw-bold nav-top-info">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="margin-right5">
                                    <path d="M326.7 403.7C304.7 411.6 280.8 416 256 416C231.2 416 207.3 411.6 185.3 403.7C184.1 403.6 184.7 403.5 184.5 403.4C154.4 392.4 127.6 374.6 105.9 352C70.04 314.6 48 263.9 48 208C48 93.12 141.1 0 256 0C370.9 0 464 93.12 464 208C464 263.9 441.1 314.6 406.1 352C405.1 353 404.1 354.1 403.1 355.1C381.7 376.4 355.7 393.2 326.7 403.7L326.7 403.7zM235.9 111.1V118C230.3 119.2 224.1 120.9 220 123.1C205.1 129.9 192.1 142.5 188.9 160.8C187.1 171 188.1 180.9 192.3 189.8C196.5 198.6 203 204.8 209.6 209.3C221.2 217.2 236.5 221.8 248.2 225.3L250.4 225.9C264.4 230.2 273.8 233.3 279.7 237.6C282.2 239.4 283.1 240.8 283.4 241.7C283.8 242.5 284.4 244.3 283.7 248.3C283.1 251.8 281.2 254.8 275.7 257.1C269.6 259.7 259.7 261 246.9 259C240.9 258 230.2 254.4 220.7 251.2C218.5 250.4 216.3 249.7 214.3 249C203.8 245.5 192.5 251.2 189 261.7C185.5 272.2 191.2 283.5 201.7 286.1C202.9 287.4 204.4 287.9 206.1 288.5C213.1 291.2 226.4 295.4 235.9 297.6V304C235.9 315.1 244.9 324.1 255.1 324.1C267.1 324.1 276.1 315.1 276.1 304V298.5C281.4 297.5 286.6 295.1 291.4 293.9C307.2 287.2 319.8 274.2 323.1 255.2C324.9 244.8 324.1 234.8 320.1 225.7C316.2 216.7 309.9 210.1 303.2 205.3C291.1 196.4 274.9 191.6 262.8 187.9L261.1 187.7C247.8 183.4 238.2 180.4 232.1 176.2C229.5 174.4 228.7 173.2 228.5 172.7C228.3 172.3 227.7 171.1 228.3 167.7C228.7 165.7 230.2 162.4 236.5 159.6C242.1 156.7 252.9 155.1 265.1 156.1C269.5 157.7 283 160.3 286.9 161.3C297.5 164.2 308.5 157.8 311.3 147.1C314.2 136.5 307.8 125.5 297.1 122.7C292.7 121.5 282.7 119.5 276.1 118.3V112C276.1 100.9 267.1 91.9 256 91.9C244.9 91.9 235.9 100.9 235.9 112V111.1zM48 352H63.98C83.43 377.9 108 399.7 136.2 416H64V448H448V416H375.8C403.1 399.7 428.6 377.9 448 352H464C490.5 352 512 373.5 512 400V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V400C0 373.5 21.49 352 48 352H48z"></path>
                                </svg>TOP UP ManjaLink
                            </span>
                        </Col> */}
                    </Row>
                </Container>
            </div>
            <Container>
                <nav className="navbar navbar-light navbar-expand-xl py-3 nav-background">
                    <Container>
                        <a className="navbar-brand d-flex align-items-center" href={`${MANJALINK_DOMAIN}`}>
                            <span><img src="assets/img/manja_logo_coloured1-300x101.png" /></span>
                        </a>
                        <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-2">
                            <span className="visually-hidden">Toggle navigation</span>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse me-1" id="navcol-2">
                            <ul className="navbar-nav ms-auto">
                                {/* <li className="nav-item"><a className="nav-link active fw-bold" href={`${MANJALINK_DOMAIN}`}>Home</a></li> */}
                                <li className="nav-item"><a className="nav-link active fw-bold" href={`${MANJALINK_DOMAIN}manjalink`}>ManjaLink</a></li>
                                <li className="nav-item"><a className="nav-link active fw-bold" href={`${MANJALINK_DOMAIN}manjapay`}>ManjaPay</a></li>
                                <li className="nav-item"><a className="nav-link active fw-bold" href={`${MANJALINK_DOMAIN}manjarewards`}>ManjaRewards</a></li>
                                <li className="nav-item"><a className="nav-link active fw-bold" href={`${MANJALINK_DOMAIN}travel-information`}>Travel Information</a></li>
                                <li className="nav-item"><a className="nav-link active fw-bold" href={`${MANJALINK_DOMAIN}get-manjalink`}>Get ManjaLink</a></li>
                                <li className="nav-item"><a className="nav-link active fw-bold" href={`${MANJALINK_DOMAIN}customer-service`}>Contact</a></li>
                            </ul>
                            
                                
                                {
                                    !props.login ? 
                                    <>
                                    <Link to="/login" className="btn btn-primary ms-md-2 login-button">
                                        <strong>Login / Join</strong>
                                    </Link>
                                    </>
                                    :
                                    <>
                                    <Dropdown className="nav-item no-arrow no-border btn ms-md-2 login-button">
                                        <Dropdown.Toggle className="nav-link no-border" variant="none" id="dropdown-basic">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-32 0 512 512" fill="currentColor" className="margin-right5">
                                                <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"></path>
                                            </svg>
                                            <strong>Welcome, {props?.userName}!</strong>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="bi bi-chevron-down margin-left5">
                                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                                            </svg> */}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/myAccount" disabled={ getRegisterStatus() == '3' ? true : false }>My Account</Dropdown.Item>
                                            <Dropdown.Item href="/login" onClick={logout}>Logout</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </>
                                }
                        </div>
                    </Container>
                </nav>
            </Container>
        </div>
    )
}
export default Header;