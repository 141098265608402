import React, { useState, useEffect } from 'react';
import { CarouselItem, Col, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import ManjaCard from './ManjaCard';
import './CardCarousel.css';
import { ArrayIntoChunk } from '../helpers/PaginationHelper';

function CardCarousel(props) {
  let cardData = props?.cardData;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
  }
  function useWindowDimensions() {
      const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

      useEffect(() => {
          function handleResize() {
              setWindowDimensions(getWindowDimensions());
          }

          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
      }, []);

      return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  if(cardData != null) {

    if(width > 768) {
    // Web view show 2 cards / page
      if(cardData.length > 2) {
        
        cardData = ArrayIntoChunk(cardData, 2)
        // console.log(cardData)
  
        return (
          <Carousel interval={null} slide={false} className="my-carousel" variant="dark">
            
              {
                cardData && cardData.map(({
                  cu_id, 
                  printed_serial, 
                  current_balance, 
                  rebate_amt, 
                  tu_timestamp, 
                  micard_design_id, 
                  top_up_amt, 
                  current_balance_timestamp, 
                  card_status, 
                  loyalty_point, 
                  is_topupable, 
                  card_exp_date, 
                  design_images, 
                  card_type_id
                }, index) => (
                  
                    <Carousel.Item>
                      <Row>
                        {
                          cardData && cardData[index].map(({
                            cu_id, 
                            printed_serial, 
                            current_balance, 
                            rebate_amt, 
                            tu_timestamp, 
                            micard_design_id, 
                            top_up_amt, 
                            current_balance_timestamp, 
                            card_status, 
                            loyalty_point, 
                            is_topupable, 
                            card_exp_date, 
                            design_images, 
                            termination_status,
                            is_terminated_and_approved,
                            micard_nickname,
                            card_type_id
                          }, index) => (
                          <Col sm={12} md={6} className="mb-3">
                              <ManjaCard 
                              manjalink_id = {props?.manjalink_id}
                              status = {card_status == "Normal" ? "1" : "0" }
                              show = {()=>props.show(true)}
                              onHide={()=>props.onHide(false)}
                              showNickname = {()=>props.showNickname(true)}
                              onHideNickname = {()=>props.onHideNickname(false)}
                              userData={props?.userData}
                              printed_serialCallback={props.printed_serialCallback}
                              cu_id = {cu_id}
                              printed_serial = {printed_serial}
                              current_balance = {current_balance}
                              rebate_amt = {rebate_amt}
                              current_balance_timestamp = {current_balance_timestamp}
                              termination_status = {termination_status}
                              is_terminated_and_approved = {is_terminated_and_approved}
                              card_exp_date = {card_exp_date}
                              design_images = {design_images}
                              micard_nickname = {micard_nickname}
                              is_topupable = {is_topupable}
                              />
                          </Col>
                          ))
                        }
                      </Row>
                    </Carousel.Item>
                  
                ))
              }
              
          </Carousel>
        );
      }
      else {
        return (
          <Row>
            {
            cardData && cardData.map(({
              cu_id, 
              printed_serial, 
              current_balance, 
              rebate_amt, 
              tu_timestamp, 
              micard_design_id, 
              top_up_amt, 
              current_balance_timestamp, 
              card_status, 
              loyalty_point, 
              is_topupable, 
              card_exp_date, 
              design_images, 
              termination_status,
              is_terminated_and_approved,
              micard_nickname,
              card_type_id
            }, index) => (
              
                <Col sm={12} md={6} className="mb-3">
                    <ManjaCard 
                    manjalink_id = {props?.manjalink_id}
                    status = {card_status == "Normal" ? "1" : "0" }
                    show = {()=>props.show(true)}
                    onHide={()=>props.onHide(false)}
                    showNickname = {()=>props.showNickname(true)}
                    onHideNickname = {()=>props.onHideNickname(false)}
                    userData = {props?.userData}
                    printed_serialCallback={props.printed_serialCallback}
                    cu_id = {cu_id}
                    printed_serial = {printed_serial}
                    current_balance = {current_balance}
                    rebate_amt = {rebate_amt}
                    current_balance_timestamp = {current_balance_timestamp}
                    termination_status = {termination_status}
                    is_terminated_and_approved = {is_terminated_and_approved}
                    card_exp_date = {card_exp_date}
                    design_images = {design_images}
                    micard_nickname = {micard_nickname}
                    is_topupable = {is_topupable}
                    />
                </Col>
            ))
            }
          </Row>
        )
      }
    }
    else {
      // Mobile View Show 1 card / page
      if(cardData.length > 1) {
        
        // cardData = ArrayIntoChunk(cardData, 1)
        
        const CardContainer = (props) => (
          <div className="mobile-cards-container">
            {
                cardData && cardData.map(({
                  cu_id, 
                  printed_serial, 
                  current_balance, 
                  rebate_amt, 
                  tu_timestamp, 
                  micard_design_id, 
                  top_up_amt, 
                  current_balance_timestamp, 
                  card_status, 
                  loyalty_point, 
                  is_topupable, 
                  card_exp_date, 
                  design_images, 
                  card_type_id,
                  is_terminated_and_approved,
                  micard_nickname,
                  termination_status
                }, index) => (
                    <ManjaCard 
                    manjalink_id = {props?.manjalink_id}
                    status = {card_status == "Normal" ? "1" : "0" }
                    show = {()=>props.show(true)}
                    onHide={()=>props.onHide(false)}
                    showNickname = {()=>props.showNickname(true)}
                    onHideNickname = {()=>props.onHideNickname(false)}
                    userData={props?.userData}
                    printed_serialCallback={props.printed_serialCallback}
                    cu_id = {cu_id}
                    printed_serial = {printed_serial}
                    current_balance = {current_balance}
                    rebate_amt = {rebate_amt}
                    current_balance_timestamp = {current_balance_timestamp}
                    termination_status = {termination_status}
                    card_exp_date = {card_exp_date}
                    design_images = {design_images}
                    is_terminated_and_approved = {is_terminated_and_approved}
                    micard_nickname = {micard_nickname}
                    is_topupable = {is_topupable}
                    />
                ))
              }
          </div>
        );
        
        return (
          <CardContainer 
          manjalink_id = {props?.manjalink_id}
          printed_serialCallback = {props?.printed_serialCallback}
          show = {()=>props.show(true)}
          onHide={()=>props.onHide(false)}
          showNickname = {()=>props.showNickname(true)}
          onHideNickname = {()=>props.onHideNickname(false)}
          userData = {props?.userData}
          />
          // <Carousel interval={null} slide={false} className="my-carousel" variant="dark">
            
              
              
          // </Carousel>
        );
      }
      else {
        return (
          <Row>
            {
            cardData && cardData.map(({
              cu_id, 
              printed_serial, 
              current_balance, 
              rebate_amt, 
              tu_timestamp, 
              micard_design_id, 
              top_up_amt, 
              current_balance_timestamp, 
              card_status, 
              loyalty_point, 
              is_topupable, 
              card_exp_date, 
              design_images, 
              termination_status,
              is_terminated_and_approved,
              micard_nickname,
              card_type_id
            }, index) => (
              
                <Col sm={12} md={6} className="mb-3">
                    <ManjaCard 
                    manjalink_id = {props?.manjalink_id}
                    status = {card_status == "Normal" ? "1" : "0" }
                    show = {()=>props.show(true)}
                    onHide={()=>props.onHide(false)}
                    showNickname = {()=>props.showNickname(true)}
                    onHideNickname = {()=>props.onHideNickname(false)}
                    userData = {props?.userData}
                    printed_serialCallback={props.printed_serialCallback}
                    cu_id = {cu_id}
                    printed_serial = {printed_serial}
                    current_balance = {current_balance}
                    rebate_amt = {rebate_amt}
                    current_balance_timestamp = {current_balance_timestamp}
                    termination_status = {termination_status}
                    is_terminated_and_approved = {is_terminated_and_approved}
                    card_exp_date = {card_exp_date}
                    design_images = {design_images}
                    micard_nickname = {micard_nickname}
                    is_topupable = {is_topupable}
                    />
                </Col>
            ))
            }
          </Row>
        )
      }
    }

  }
}
export default CardCarousel;