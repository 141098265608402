import React, { useState, useEffect } from "react";
import './OnlineReload.css';

function OnlineReloadStep(props) {
    const step2className = props.step == 1 ? "step-not-done" : "step-done"
    const [dash, setDash] = useState("- - - -");
    // const [dash, setDash] = useState("- - - - - - - - - - - - -");

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    
        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
    
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);
    
        return windowDimensions;
    }
    
    const { height, width } = useWindowDimensions();
    useEffect(()=> {
        if(width > 280) {
            setDash("- - - -")
        }
        if(width > 370) {
            setDash("- - - - -")
        }
        if(width > 480) {
            setDash("- - - - - -")
        }
        if(width > 768) {
            setDash("- - - - - - - - - - -")
        }
        if(width > 992) {
            setDash("- - - - - - - - - - - - -")
        }
    },[width])

    // console.log(width)

    return(
        <div className="m-auto">
            <div className="text-center">
                <span className="dash-for-step" >{dash}</span>
                <div className="table-responsive text-center m-auto mw-800">
                    <table className="table reload-step-table">
                        <thead>
                            <tr>
                                <th>
                                    <span className="step-done">01</span>
                                </th>
                                <th>
                                    <span className={step2className}>02</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bs-none">
                            <tr>
                                <td className="font-sub-title-large text-webkit-center"><p>Online Reload &amp; Top Up Service</p></td>
                                <td className="font-sub-title-large text-webkit-center"><p>Tap your card on the bus or at the counter within 30 days for travel credit activation</p></td>
                            </tr>
                            <tr></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default OnlineReloadStep;