import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { checkIsTerminatedDate, changeDateFormatToDDMMMYYYY, convertTimeStampTimeToAMPM, changeDateTimeFormatToDDMMMYYYY } from "../helpers/DateHelper";
import MyToolTips from "./TooltipsInformation";

function ManjaCard(props) {

	const MANJA_IMAGE_URL = process.env.REACT_APP_MANJA_IMAGE_URL;
	const topUp = () => {
		window.location.href = '/topUp'
	}
	//     cu_id
	// printed_serial
	// current_balance
	// tu_timestamp
	// card_exp_date
	// design_images
	// console.log(props)
	// console.log(checkIsTerminatedDate(changeDateFormatToDDMMMYYYY(props?.card_exp_date)))
	const today = new Date('2020-01-01');
	// console.log(today);

	const manjalink_id = props?.manjalink_id

	const getCardStatus = (exp_date) => {
		const expDate = new Date(exp_date)
		
		const topup_link = () => {
			if (props?.is_topupable == 'True') {
				return <span>Inactive
					<br />( to reactivate your card, simply perform a &nbsp;
					<Link to='/topUp' state={{
						title: "ManjaLink",
						manjalink_id: manjalink_id,
						printed_serial: props?.printed_serial,
						userData: props?.userData,
						cu_id: `${props?.cu_id}`,
						design_images: `${props?.design_images}`,
						current_balance: `${props?.current_balance}`,
						rebate_amt: `${props?.rebate_amt}`,
						card_exp_date: `${props?.card_exp_date}`
					}}>top-up</Link> )</span>
			}
			else {
				return <span>Inactive</span>
			}
		}

		if (expDate < today) {
			return topup_link();
		}
		else {
			return "Active"
		}
	}

	const getExpiredDetails = (exp_date) => {
		const expDate = new Date(exp_date)
		if (expDate < today) {
			return "Card expired prior to " + changeDateFormatToDDMMMYYYY(exp_date) + ":"
		}
		else {
			return "Card expired on or after " + changeDateFormatToDDMMMYYYY(exp_date) + ":"
		}
	}

	if (props.status == 1 && props.termination_status != 'A' && props.termination_status != 'C' && props.is_terminated_and_approved == 'False') {
		return (
			<div className="card br-20">
				<Container>
					<Row className="mb-3 pt-3">
						<Col sm={12} md={5} lg={4}>
							<img src={`${MANJA_IMAGE_URL}${props.design_images}`} />
						</Col>
						<Col sm={12} md={7} lg={8} className="mobile-padding">
							<p className="mb-1 font-title fw-bold">{props?.micard_nickname || "Add Nickname"}
								<i class="far fa-edit ps-1 font-primary-success pointer" aria-hidden="true" onClick={() => { props?.showNickname(true); { props?.printed_serialCallback(props?.printed_serial) } }} ></i>
								<div className="dropdown no-arrow float-end">
									<button className="btn btn-link btn-sm" aria-expanded="false" data-bs-toggle="dropdown" type="button">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="-192 0 512 512" fill="black" className="float-end mt-1 lead pointer">
											<path d="M64 360C94.93 360 120 385.1 120 416C120 446.9 94.93 472 64 472C33.07 472 8 446.9 8 416C8 385.1 33.07 360 64 360zM64 200C94.93 200 120 225.1 120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200zM64 152C33.07 152 8 126.9 8 96C8 65.07 33.07 40 64 40C94.93 40 120 65.07 120 96C120 126.9 94.93 152 64 152z"></path>
										</svg>
									</button>
									<div className="dropdown-menu shadow dropdown-menu-end animated--fade-in">
										<Link to="/cancelCard" className="dropdown-item"
											state={{
												manjalink_id: manjalink_id,
												printed_serial: props?.printed_serial,
												cu_id: `${props?.cu_id}`,
												design_images: `${props?.design_images}`,
												current_balance: `${props?.current_balance}`,
												rebate_amt: `${props?.rebate_amt}`,
												card_exp_date: `${props?.card_exp_date}`
											}}>Lost / Cancel Card</Link>
									</div>
								</div>
							</p>
							<p className="mb-0 font-sub-title">Available Balance</p>
							<h4 className="fw-bold">{props?.current_balance != "" ? props?.current_balance : "0.00"} TCR</h4>
							<p className="mb-0 font-sub-title">Pending Balance</p>
							<h4 className="fw-bold">{props?.rebate_amt != null ? props?.rebate_amt : "0.00"} TCR</h4>
							<p className="mb-0 font-sub-title">Last Update: {props?.current_balance_timestamp != "" ? convertTimeStampTimeToAMPM(props?.current_balance_timestamp) : "-"}</p>
							<p className="font-grey font-sub-title">Latest credit update will be reflected within 1 working day&nbsp;
								<MyToolTips
									title="After topping up online, the pending balance will be credited after card tapping; at counter (immediately) or when you ride the bus (must be after 3 hours)."
								/>
							</p>
							<Row>
								<Col className="text-center d-flex justify-content-center align-items-center">
									{
										props?.is_topupable == 'True' ?
											<Link to='/topUp' state={{
												title: "ManjaLink",
												manjalink_id: manjalink_id,
												printed_serial: props?.printed_serial,
												userData: props?.userData,
												cu_id: `${props?.cu_id}`,
												design_images: `${props?.design_images}`,
												current_balance: `${props?.current_balance}`,
												rebate_amt: `${props?.rebate_amt}`,
												card_exp_date: `${props?.card_exp_date}`
											}} className="font-primary-success topup-button">
												<button className="btn btn-primary topup-button" type="button" >
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="pe-0 me-1">
														<path d="M326.7 403.7C304.7 411.6 280.8 416 256 416C231.2 416 207.3 411.6 185.3 403.7C184.1 403.6 184.7 403.5 184.5 403.4C154.4 392.4 127.6 374.6 105.9 352C70.04 314.6 48 263.9 48 208C48 93.12 141.1 0 256 0C370.9 0 464 93.12 464 208C464 263.9 441.1 314.6 406.1 352C405.1 353 404.1 354.1 403.1 355.1C381.7 376.4 355.7 393.2 326.7 403.7L326.7 403.7zM235.9 111.1V118C230.3 119.2 224.1 120.9 220 123.1C205.1 129.9 192.1 142.5 188.9 160.8C187.1 171 188.1 180.9 192.3 189.8C196.5 198.6 203 204.8 209.6 209.3C221.2 217.2 236.5 221.8 248.2 225.3L250.4 225.9C264.4 230.2 273.8 233.3 279.7 237.6C282.2 239.4 283.1 240.8 283.4 241.7C283.8 242.5 284.4 244.3 283.7 248.3C283.1 251.8 281.2 254.8 275.7 257.1C269.6 259.7 259.7 261 246.9 259C240.9 258 230.2 254.4 220.7 251.2C218.5 250.4 216.3 249.7 214.3 249C203.8 245.5 192.5 251.2 189 261.7C185.5 272.2 191.2 283.5 201.7 286.1C202.9 287.4 204.4 287.9 206.1 288.5C213.1 291.2 226.4 295.4 235.9 297.6V304C235.9 315.1 244.9 324.1 255.1 324.1C267.1 324.1 276.1 315.1 276.1 304V298.5C281.4 297.5 286.6 295.1 291.4 293.9C307.2 287.2 319.8 274.2 323.1 255.2C324.9 244.8 324.1 234.8 320.1 225.7C316.2 216.7 309.9 210.1 303.2 205.3C291.1 196.4 274.9 191.6 262.8 187.9L261.1 187.7C247.8 183.4 238.2 180.4 232.1 176.2C229.5 174.4 228.7 173.2 228.5 172.7C228.3 172.3 227.7 171.1 228.3 167.7C228.7 165.7 230.2 162.4 236.5 159.6C242.1 156.7 252.9 155.1 265.1 156.1C269.5 157.7 283 160.3 286.9 161.3C297.5 164.2 308.5 157.8 311.3 147.1C314.2 136.5 307.8 125.5 297.1 122.7C292.7 121.5 282.7 119.5 276.1 118.3V112C276.1 100.9 267.1 91.9 256 91.9C244.9 91.9 235.9 100.9 235.9 112V111.1zM48 352H63.98C83.43 377.9 108 399.7 136.2 416H64V448H448V416H375.8C403.1 399.7 428.6 377.9 448 352H464C490.5 352 512 373.5 512 400V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V400C0 373.5 21.49 352 48 352H48z"></path>
													</svg>Top Up
												</button>
											</Link>
											:
											<button className="btn btn-primary topup-button" type="button" disabled >
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="pe-0 me-1">
													<path d="M326.7 403.7C304.7 411.6 280.8 416 256 416C231.2 416 207.3 411.6 185.3 403.7C184.1 403.6 184.7 403.5 184.5 403.4C154.4 392.4 127.6 374.6 105.9 352C70.04 314.6 48 263.9 48 208C48 93.12 141.1 0 256 0C370.9 0 464 93.12 464 208C464 263.9 441.1 314.6 406.1 352C405.1 353 404.1 354.1 403.1 355.1C381.7 376.4 355.7 393.2 326.7 403.7L326.7 403.7zM235.9 111.1V118C230.3 119.2 224.1 120.9 220 123.1C205.1 129.9 192.1 142.5 188.9 160.8C187.1 171 188.1 180.9 192.3 189.8C196.5 198.6 203 204.8 209.6 209.3C221.2 217.2 236.5 221.8 248.2 225.3L250.4 225.9C264.4 230.2 273.8 233.3 279.7 237.6C282.2 239.4 283.1 240.8 283.4 241.7C283.8 242.5 284.4 244.3 283.7 248.3C283.1 251.8 281.2 254.8 275.7 257.1C269.6 259.7 259.7 261 246.9 259C240.9 258 230.2 254.4 220.7 251.2C218.5 250.4 216.3 249.7 214.3 249C203.8 245.5 192.5 251.2 189 261.7C185.5 272.2 191.2 283.5 201.7 286.1C202.9 287.4 204.4 287.9 206.1 288.5C213.1 291.2 226.4 295.4 235.9 297.6V304C235.9 315.1 244.9 324.1 255.1 324.1C267.1 324.1 276.1 315.1 276.1 304V298.5C281.4 297.5 286.6 295.1 291.4 293.9C307.2 287.2 319.8 274.2 323.1 255.2C324.9 244.8 324.1 234.8 320.1 225.7C316.2 216.7 309.9 210.1 303.2 205.3C291.1 196.4 274.9 191.6 262.8 187.9L261.1 187.7C247.8 183.4 238.2 180.4 232.1 176.2C229.5 174.4 228.7 173.2 228.5 172.7C228.3 172.3 227.7 171.1 228.3 167.7C228.7 165.7 230.2 162.4 236.5 159.6C242.1 156.7 252.9 155.1 265.1 156.1C269.5 157.7 283 160.3 286.9 161.3C297.5 164.2 308.5 157.8 311.3 147.1C314.2 136.5 307.8 125.5 297.1 122.7C292.7 121.5 282.7 119.5 276.1 118.3V112C276.1 100.9 267.1 91.9 256 91.9C244.9 91.9 235.9 100.9 235.9 112V111.1zM48 352H63.98C83.43 377.9 108 399.7 136.2 416H64V448H448V416H375.8C403.1 399.7 428.6 377.9 448 352H464C490.5 352 512 373.5 512 400V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V400C0 373.5 21.49 352 48 352H48z"></path>
												</svg>Top Up
											</button>
										// <div className="font-dark-grey hover-none">
										// Top Up
										// </div>
									}
								</Col>
								<Col className="text-center d-flex justify-content-center align-items-center">
									<Link to='/transactionHistory'
										state={{
											manjalink_id: manjalink_id,
											cu_id: `${props?.cu_id}`,
											printed_serial: `${props?.printed_serial}`,
											userData: props?.userData,
											design_images: `${props?.design_images}`,
											current_balance: `${props?.current_balance}`,
											rebate_amt: `${props?.rebate_amt}`,
											card_exp_date: `${props?.card_exp_date}`
										}} className="font-primary-success">
										Transaction
										<i className="fas fa-chevron-right ms-1"></i>
									</Link>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
				<Row className="card-extra-info-background card-extra-info g-0 m-0">
					<Col>
						<div className="">
							<p>CARD ID: {props?.printed_serial}</p>
							{/* <p>{getExpiredDetails(props?.card_exp_date)}</p> */}
							{/* <p>Expiry Date: {!checkIsTerminatedDate(changeDateFormatToDDMMMYYYY(props?.card_exp_date)) ? changeDateFormatToDDMMMYYYY(props?.card_exp_date) : "-"}</p> */}
							<p>Status: {getCardStatus(props?.card_exp_date)}</p>
						</div>
					</Col>
				</Row>
			</div>
		)
	}
	else {
		return (
			<div className="card br-20 inactive-background">
				<Container>
					<Row className="mb-3 pt-3">
						<Col sm={12} md={5} lg={4}>
							<img src={`${MANJA_IMAGE_URL}${props?.design_images}`} />
						</Col>
						<Col sm={12} md={7} lg={8} className="mobile-padding">
							<p className="mb-0 font-sub-title">Available Balance

								<div className="dropdown no-arrow float-end">
									{
										props.termination_status == 'A' || props.termination_status == 'C' || props.is_terminated_and_approved == 'True' ?
											<>
												<button className="btn btn-link btn-sm" aria-expanded="false" data-bs-toggle="dropdown" type="button">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="-192 0 512 512" fill="black" className="float-end mt-1 lead pointer">
														<path d="M64 360C94.93 360 120 385.1 120 416C120 446.9 94.93 472 64 472C33.07 472 8 446.9 8 416C8 385.1 33.07 360 64 360zM64 200C94.93 200 120 225.1 120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200zM64 152C33.07 152 8 126.9 8 96C8 65.07 33.07 40 64 40C94.93 40 120 65.07 120 96C120 126.9 94.93 152 64 152z"></path>
													</svg>
												</button>

												<div className="dropdown-menu shadow dropdown-menu-end animated--fade-in">
													<a className="dropdown-item pointer" onClick={() => { props?.show(true); { props?.printed_serialCallback(props?.printed_serial) } }}>Remove Card</a>
												</div>
											</>
											:
											props.termination_status == 'R' ?
												<>
													<button className="btn btn-link btn-sm" aria-expanded="false" data-bs-toggle="dropdown" type="button">
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="-192 0 512 512" fill="black" className="float-end mt-1 lead pointer">
															<path d="M64 360C94.93 360 120 385.1 120 416C120 446.9 94.93 472 64 472C33.07 472 8 446.9 8 416C8 385.1 33.07 360 64 360zM64 200C94.93 200 120 225.1 120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200zM64 152C33.07 152 8 126.9 8 96C8 65.07 33.07 40 64 40C94.93 40 120 65.07 120 96C120 126.9 94.93 152 64 152z"></path>
														</svg>
													</button>

													<div className="dropdown-menu shadow dropdown-menu-end animated--fade-in">
														<Link to="/cancelCard" className="dropdown-item"
															state={{
																manjalink_id: manjalink_id,
																printed_serial: props?.printed_serial,
																cu_id: `${props?.cu_id}`,
																design_images: `${props?.design_images}`,
																current_balance: `${props?.current_balance}`,
																rebate_amt: `${props?.rebate_amt}`,
																card_exp_date: `${props?.card_exp_date}`
															}}>Re-apply Lost / Cancel Card</Link>
													</div>
												</>
												: ""
									}
								</div>
							</p>
							<h4 className="fw-bold">{props?.current_balance != "" ? props?.current_balance : "0.00"} TCR</h4>
							<p className="mb-0 font-sub-title">Pending Balance</p>
							<h4 className="fw-bold">{props?.rebate_amt != null ? props?.rebate_amt : "0.00"} TCR</h4>
							<p className="mb-0 font-sub-title">Last Update: {props?.current_balance_timestamp != "" ? changeDateTimeFormatToDDMMMYYYY(convertTimeStampTimeToAMPM(props?.current_balance_timestamp)) : "-"}</p>
							<p className="font-grey font-sub-title">Latest credit update will be reflected within 1 working day&nbsp;
								<MyToolTips
									title="After topping up online, the pending balance will be credited after card tapping; at counter (immediately) or when you ride the bus (must be after 3 hours)."
								/>
							</p>
							<Row>
								<Col className="text-center d-flex justify-content-center align-items-center">
									<button className="btn btn-primary topup-button-grey no-events" type="button">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="pe-0 me-1">
											<path d="M326.7 403.7C304.7 411.6 280.8 416 256 416C231.2 416 207.3 411.6 185.3 403.7C184.1 403.6 184.7 403.5 184.5 403.4C154.4 392.4 127.6 374.6 105.9 352C70.04 314.6 48 263.9 48 208C48 93.12 141.1 0 256 0C370.9 0 464 93.12 464 208C464 263.9 441.1 314.6 406.1 352C405.1 353 404.1 354.1 403.1 355.1C381.7 376.4 355.7 393.2 326.7 403.7L326.7 403.7zM235.9 111.1V118C230.3 119.2 224.1 120.9 220 123.1C205.1 129.9 192.1 142.5 188.9 160.8C187.1 171 188.1 180.9 192.3 189.8C196.5 198.6 203 204.8 209.6 209.3C221.2 217.2 236.5 221.8 248.2 225.3L250.4 225.9C264.4 230.2 273.8 233.3 279.7 237.6C282.2 239.4 283.1 240.8 283.4 241.7C283.8 242.5 284.4 244.3 283.7 248.3C283.1 251.8 281.2 254.8 275.7 257.1C269.6 259.7 259.7 261 246.9 259C240.9 258 230.2 254.4 220.7 251.2C218.5 250.4 216.3 249.7 214.3 249C203.8 245.5 192.5 251.2 189 261.7C185.5 272.2 191.2 283.5 201.7 286.1C202.9 287.4 204.4 287.9 206.1 288.5C213.1 291.2 226.4 295.4 235.9 297.6V304C235.9 315.1 244.9 324.1 255.1 324.1C267.1 324.1 276.1 315.1 276.1 304V298.5C281.4 297.5 286.6 295.1 291.4 293.9C307.2 287.2 319.8 274.2 323.1 255.2C324.9 244.8 324.1 234.8 320.1 225.7C316.2 216.7 309.9 210.1 303.2 205.3C291.1 196.4 274.9 191.6 262.8 187.9L261.1 187.7C247.8 183.4 238.2 180.4 232.1 176.2C229.5 174.4 228.7 173.2 228.5 172.7C228.3 172.3 227.7 171.1 228.3 167.7C228.7 165.7 230.2 162.4 236.5 159.6C242.1 156.7 252.9 155.1 265.1 156.1C269.5 157.7 283 160.3 286.9 161.3C297.5 164.2 308.5 157.8 311.3 147.1C314.2 136.5 307.8 125.5 297.1 122.7C292.7 121.5 282.7 119.5 276.1 118.3V112C276.1 100.9 267.1 91.9 256 91.9C244.9 91.9 235.9 100.9 235.9 112V111.1zM48 352H63.98C83.43 377.9 108 399.7 136.2 416H64V448H448V416H375.8C403.1 399.7 428.6 377.9 448 352H464C490.5 352 512 373.5 512 400V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V400C0 373.5 21.49 352 48 352H48z"></path>
										</svg>Top Up
									</button>
									{/* <div className="font-dark-grey hover-none">
                                    Top Up
                                    </div> */}
								</Col>
								<Col className="text-center d-flex justify-content-center align-items-center">
									<Link to='/transactionHistory'
										state={{
											manjalink_id: manjalink_id,
											cu_id: `${props?.cu_id}`,
											printed_serial: `${props?.printed_serial}`,
											userData: props?.userData,
											design_images: `${props?.design_images}`,
											current_balance: `${props?.current_balance}`,
											rebate_amt: `${props?.rebate_amt}`,
											card_exp_date: `${props?.card_exp_date}`,
											card_is_cancelled: true
										}} className="font-dark-grey">
										Transaction
										<i className="fas fa-chevron-right ms-1"></i>
									</Link>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
				<div className="mb-4"></div>
				<Row className="card-extra-info-background card-extra-info g-0 mb-1">
					<Col xs={8} sm={8} className="d-flex align-items-center">
						<div>
							<p>ID: {props?.printed_serial}</p>
							{/* <p>Expiry Date: {!checkIsTerminatedDate(changeDateFormatToDDMMMYYYY(props?.card_exp_date)) ? changeDateFormatToDDMMMYYYY(props?.card_exp_date) : "-"}</p> */}
						</div>
					</Col>
					<Col xs={4} sm={4} className="d-flex justify-content-end align-items-center">
						<button className="btn btn-primary cancel-button me-2" type="button">
							{
								props.is_terminated_and_approved == 'True' ?
									"Cancelled"
									:
									props.termination_status == 'A' || props.termination_status == 'C' ?
										"Cancelled"
										:
										props.termination_status == 'R' ?
											"Rejected"
											:
											props.termination_status == 'P' ?
												"Pending"
												:
												"Pending"
							}
						</button>
					</Col>
				</Row>
			</div>
		)
	}
}
export default ManjaCard;