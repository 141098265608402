import React from "react";
import './OnlineReload.css';

function OnlineTopUp(props) {
    const selected = (amount) => {
        const topUp1 = document.getElementById("30")
        const topUp2 = document.getElementById("50")
        const topUp3 = document.getElementById("150")
        const topUp4 = document.getElementById("300")
        topUp1.classList.remove("selected");
        topUp2.classList.remove("selected");
        topUp3.classList.remove("selected");
        topUp4.classList.remove("selected");
        const el = document.getElementById(amount);
        el.classList.add("selected");
        // console.log(amount)
        props?.selected(amount);
    }
    return (
        <div className="row">
            <div onClick={(e)=>selected("30")} id="30" className="col d-flex d-xxl-flex flex-column justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center online-top-up-container pointer">
                <h3 className="text-center font-primary-success font-sub-title-larger fw-bold">RM 30.00</h3>
            </div>
            <div onClick={(e)=>selected("50")}  id="50" className="col d-flex d-xxl-flex flex-column justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center online-top-up-container pointer">
                <h3 className="text-center font-primary-success font-sub-title-larger fw-bold">RM 50.00</h3>
            </div>
            <div onClick={(e)=>selected("150")}  id="150" className="col d-flex d-xxl-flex flex-column justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center online-top-up-container pointer">
                <h3 className="text-center font-primary-success font-sub-title-larger fw-bold">RM 150.00</h3>
                {/* <h6 className="text-center fs-italic font-sub-title">(FREE RM5)</h6> */}
            </div>
            <div onClick={(e)=>selected("300")}  id="300" className="col d-flex d-xxl-flex flex-column justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center online-top-up-container pointer">
                <h3 className="text-center font-primary-success font-sub-title-larger fw-bold">RM 300.00</h3>
                {/* <h6 className="text-center fs-italic font-sub-title">(FREE RM12)</h6> */}
            </div>
        </div>
    )
}
export default OnlineTopUp;